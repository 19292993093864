var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu action-menu" },
    [
      _vm.interactionType === "proximity"
        ? _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Activation Radius")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form[_vm.interactionType].activation_radius,
                  expression: "form[interactionType].activation_radius",
                },
              ],
              staticClass: "form-control form-control-sm",
              attrs: { type: "number" },
              domProps: {
                value: _vm.form[_vm.interactionType].activation_radius,
              },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.form[_vm.interactionType],
                    "activation_radius",
                    $event.target.value
                  )
                },
              },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Action")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form[_vm.interactionType].action_type,
                expression: "form[interactionType].action_type",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text", id: "action-name" },
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.form[_vm.interactionType],
                  "action_type",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { attrs: { value: "api_call" } }, [
              _vm._v("API Call"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "external_call" } }, [
              _vm._v("External Javascript"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "custom_code" } }, [
              _vm._v("Custom Code"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "show_message" } }, [
              _vm._v("Show Message"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "show_alert" } }, [
              _vm._v("Show Alert"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "show_web" } }, [
              _vm._v("Show Webpage"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "smart_contract" } }, [
              _vm._v("Smart Contract"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "teleport_to_metaverse" } }, [
              _vm._v("Teleport to another Metaverse"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "show_nft_single" } }, [
              _vm._v("Show NFT Information"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "start_dialogue" } }, [
              _vm._v("Start Dialogue"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "rotate_object" } }, [
              _vm._v("Rotate Object"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "animate_object" } }, [
              _vm._v("Animate Object"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "show_object" } }, [
              _vm._v("Show Object"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "hide_object" } }, [
              _vm._v("Hide Object"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "hide_object_multi" } }, [
              _vm._v("Hide Object (Multiple)"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "show_advertisement" } }, [
              _vm._v("Show Advertisement"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "show_object_swap" } }, [
              _vm._v("Swap Object"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "join_meeting_prompt" } }, [
              _vm._v("Join Meeting"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "mint_nft_prompt" } }, [
              _vm._v("Mint NFT (Solana)"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "move_character_to" } }, [
              _vm._v("Move character to"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "move_character_along" } }, [
              _vm._v("Move character along"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "teleport_character_to" } }, [
              _vm._v("Teleport character to"),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(_vm.toComponent(_vm.form[_vm.interactionType].action_type), {
        tag: "component",
        attrs: {
          "alpha-id": _vm.alphaId,
          "interaction-type": _vm.interactionType,
          action: _vm.form,
        },
        on: { save: _vm.update },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 menu ps-0" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("On Success")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form[_vm.interactionType].on_success,
                    expression: "form[interactionType].on_success",
                  },
                ],
                staticClass: "form-control",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form[_vm.interactionType],
                        "on_success",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    _vm.onChange,
                  ],
                },
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v("Nothing"),
                ]),
                _vm._v(" "),
                _vm._l(
                  _vm.$store.state.metaverse.manifest.actions,
                  function (action, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: key } },
                      [
                        _vm._v(
                          _vm._s(key) + " (" + _vm._s(action.action_type) + ")"
                        ),
                      ]
                    )
                  }
                ),
              ],
              2
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6 menu pe-0" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("On Error")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form[_vm.interactionType].on_error,
                    expression: "form[interactionType].on_error",
                  },
                ],
                staticClass: "form-control",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form[_vm.interactionType],
                        "on_error",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    _vm.onChange,
                  ],
                },
              },
              [
                _c("option", { domProps: { value: null } }, [
                  _vm._v("Nothing"),
                ]),
                _vm._v(" "),
                _vm._l(
                  _vm.$store.state.metaverse.manifest.actions,
                  function (action, key) {
                    return _c(
                      "option",
                      { key: key, domProps: { value: key } },
                      [
                        _vm._v(
                          _vm._s(key) + " (" + _vm._s(action.action_type) + ")"
                        ),
                      ]
                    )
                  }
                ),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }