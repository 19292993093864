import { render, staticRenderFns } from "./HideObject.vue?vue&type=template&id=03f13515&scoped=true&"
import script from "./HideObject.vue?vue&type=script&lang=js&"
export * from "./HideObject.vue?vue&type=script&lang=js&"
import style0 from "./HideObject.vue?vue&type=style&index=0&id=03f13515&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03f13515",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('03f13515')) {
      api.createRecord('03f13515', component.options)
    } else {
      api.reload('03f13515', component.options)
    }
    module.hot.accept("./HideObject.vue?vue&type=template&id=03f13515&scoped=true&", function () {
      api.rerender('03f13515', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/editor/actions/HideObject.vue"
export default component.exports