var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form", { staticClass: "menu" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Object")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.target_id,
            expression: "form.target_id",
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.form.target_id },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "target_id", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group", attrs: { hidden: "" } }, [
      _c("label", [_vm._v("Advert Screen")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.advert_screen_id,
            expression: "form.advert_screen_id",
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.form.advert_screen_id },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "advert_screen_id", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Duration")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.duration,
            expression: "form.duration",
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.form.duration },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "duration", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.mediaItems,
            expression: "mediaItems",
          },
        ],
        staticClass: "form-control",
        attrs: { rows: "5" },
        domProps: { value: _vm.mediaItems },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.mediaItems = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Mesh Names")]),
        _vm._v(" "),
        _vm._l(_vm.form.mesh_names, function (obj, mesh) {
          return _c("div", { key: mesh, staticClass: "row mb-2" }, [
            _c("div", { staticClass: "col-5 ps-0" }, [
              _c("label", { staticClass: "col-form-label col-sm-auto green" }, [
                _vm._v("Name:"),
              ]),
              _vm._v(" "),
              _c("input", {
                staticClass: "form-control",
                attrs: { readonly: "" },
                domProps: { value: mesh },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col row px-0" }, [
              _c("div", { staticClass: "col-sm-12 col-md-6 row" }, [
                _c(
                  "label",
                  { staticClass: "col-form-label col-sm-auto green" },
                  [_vm._v("X:")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-10 px-0" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.form.mesh_names[mesh].x,
                        expression: "form.mesh_names[mesh].x",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { min: "1", type: "number", step: "1" },
                    domProps: { value: _vm.form.mesh_names[mesh].x },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form.mesh_names[mesh],
                          "x",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-12 col-md-6 row" }, [
                _c("label", { staticClass: "col-form-label col-sm-auto red" }, [
                  _vm._v("Y:"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-10 px-0" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number",
                        value: _vm.form.mesh_names[mesh].y,
                        expression: "form.mesh_names[mesh].y",
                        modifiers: { number: true },
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { min: "1", type: "number", step: "1" },
                    domProps: { value: _vm.form.mesh_names[mesh].y },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.form.mesh_names[mesh],
                          "y",
                          _vm._n($event.target.value)
                        )
                      },
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-auto px-0" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger btn-sm py-0 mt-5",
                  on: {
                    click: function ($event) {
                      return _vm.removeMesh(mesh)
                    },
                  },
                },
                [_vm._v("X")]
              ),
            ]),
          ])
        }),
        _vm._v(" "),
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newMeshName,
                expression: "newMeshName",
              },
            ],
            staticClass: "form-control my-0",
            attrs: { placeholder: "Mesh Name" },
            domProps: { value: _vm.newMeshName },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.newMeshName = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "btn btn-primary", on: { click: _vm.addMesh } },
            [_vm._v("ADD")]
          ),
        ]),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("label", [
      _vm._v("Media "),
      _c("small", [_c("i", [_vm._v("(One URL Per Line)")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }