var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "action" }, [
    _c("form", { staticClass: "form menu" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Spots")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model.number",
              value: _vm.spots,
              expression: "spots",
              modifiers: { number: true },
            },
          ],
          staticClass: "form-control",
          attrs: { type: "number" },
          domProps: { value: _vm.spots },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.spots = _vm._n($event.target.value)
            },
            blur: function ($event) {
              return _vm.$forceUpdate()
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Repeatable")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.repeatable,
              expression: "form.repeatable",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.form.repeatable)
              ? _vm._i(_vm.form.repeatable, null) > -1
              : _vm.form.repeatable,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.form.repeatable,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && _vm.$set(_vm.form, "repeatable", $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.form,
                      "repeatable",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.form, "repeatable", $$c)
              }
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group mb-3" },
        [
          _c("label", [_vm._v("Start")]),
          _vm._v(" "),
          _vm._l(_vm.spots, function (index) {
            return _c("Vector3", {
              key: index,
              model: {
                value: _vm.form.positions[index - 1],
                callback: function ($$v) {
                  _vm.$set(_vm.form.positions, index - 1, $$v)
                },
                expression: "form.positions[index - 1]",
              },
            })
          }),
          _vm._v(" "),
          _c("label", [_vm._v("End")]),
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-block",
              on: { click: _vm.save },
            },
            [_vm._v("Update")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }