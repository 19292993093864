<template>
	<div class="light ambient-light menu">
<!--		<div class="form-group">-->
<!--			<label>Light Type</label>-->
<!--			<input class="form-control" readonly v-model="form.type">-->
<!--		</div>-->

		<div class="form-group">
			<label>Color</label>
			<input class="form-control" type="color" :value="form.color" @change="(e) => form.color = e.target.value">
		</div>

		<div class="form-group">
			<label>Intensity</label>
			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" step="0.1"  v-model.number="form.intensity">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.intensity" min="0.0" max="1.0" step="0.01">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "AmbientLight",
	props: {
		light: {
			type: Object,
			required: true
		}
	},
	watch: {
		form: {
			handler: function(form) {
				this.$emit("update", form);
			},
			deep: true
		}
	},
	data() {
		return {
			form: {
				type: "ambient",
				color: "#ffffff",
				intensity: 1
			},
		}
	},
	beforeMount() {
		this.form = this.light;
		// this.form.color = this.form.color.replace("0x", "#");
	}
}
</script>

<style scoped>

</style>