<template>
	<div class="action">
		<form class="menu">
			<div class="form-group">
				<label>Action</label>
				<input disabled value="smart_contract_call" class="form-control">
			</div>

			<div class="form-group">
				<label>Chain</label>
				<!--				TODO Verify chain ID's-->
				<select v-model="form.chain_id" class="form-control">
					<option value="0">Solana</option>
					<option value="1">Ethereum</option>
					<option value="2">BSC</option>
					<option value="3">Matic</option>
				</select>
			</div>

			<div class="form-group">
				<label>Contract Address</label>
				<input v-model="form.contract_addr" class="form-control">
			</div>

			<div class="form-group">
				<label>Is Payable</label>
				<input v-model="form.is_payable" type="checkbox" class="form-control">
			</div>

			<div class="form-group">
				<label>Function Name</label>
				<input v-model="form.method" class="form-control">
			</div>

			<div class="form-group">
				<label>Default Parameters</label>

				<div class="row" v-for="(value,key) in form.params" :key="key">
					<input type="text" v-model="form.params[key]" placeholder="Default Value"/>
				</div>
				<!--				TODO Key -> Value -->

				<button @click="form.params.push('')">ADD</button>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
	name: "SmartContractCall",
	mixins: [ actionsMixin ],
	data() {
		return {
			form: {
				chain_id: -1,
				contract_addr: "0x0",
				method: "",
				is_payable: false,
				params: []
			}
		}
	},
})
</script>

<style scoped>

</style>
