<template>
	<div>
		<div class="form-group">
			<label>Target</label>
			<input v-model="form.target" class="form-control">
		</div>

		<div class="form-group">
			<label>Meeting ID</label>
			<input v-model="form.room_id" class="form-control">
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import actionsMixin from '@/components/editor/actions/actionsMixin'
import User from '@/js/api/user'

export default Vue.extend({
	name: 'JoinMeeting',
	mixins: [actionsMixin],
	data() {
		return {
			form: {
				target: "",
				room_id: "",
			},
		}
	},
	methods: {
		//
	},
	mounted() {
		//
	}
})
</script>

<style lang="scss" scoped>
.form-control {
	font-size: 0.8rem;
	background: unset;
	color: white;
	text-align: left;
}

option {
	color: black !important;
}
</style>
