<template>
	<form class="menu">
		<div class="form-group">
			<label>Options</label>
			<select class="form-control p-0" :style="{height: `${(entities.length * 15)/3}px`, fontSize: '1rem'}" multiple v-model="form.options">
				<option :class="{selected : form.options.indexOf(entity.id) > -1}" v-for="(entity,key) in entities" :key="key" :value="entity.id">{{ entity.data.model }}</option>
			</select>
		</div>
		<div class="form-group">
			<label>Default Object</label>
			<select class="form-control" v-model="form.target_id">
				<option v-for="(entity,key) in form.options" :key="key" :value="entity">{{ entity }}</option>
			</select>
		</div>
	</form>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
	name: 'SwapObject',
	mixins: [actionsMixin],
	data() {
		return {
			form: {
				target_id: null,
				options: [],
			},
		}
	},
	computed: {

		entities: function () {
			if (this.$store.state.metaverse.manifest.entities === null)
				return [];

			return this.$store.state.metaverse.manifest.entities
		}
	},
	methods: {
		setSelf: function (e) {
			e.preventDefault()

			this.form.target_id = this.alphaId
		}
	},
})
</script>

<style lang="scss" scoped>
.form-control {
	font-size: 0.8rem;
	background: unset;
	color: white;
	text-align: left;
}
</style>
