<template>
	<div class="action menu">
		<form class="menu">
			<div class="form-group">
				<label>Title</label>
				<input v-model="form.title" class="form-control">
			</div>

			<div class="form-group">
				<label>Message</label>
				<input v-model="form.message" class="form-control">
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'
import Vector3 from "../fields/Vector3";

export default Vue.extend({
	name: "ShowAlertCall",
	components: {Vector3},
	mixins: [actionsMixin],
	data() {
		return {
			form: {
				title: "",
				message: "",
			}
		}
	},
})
</script>

<style scoped>

</style>
