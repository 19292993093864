var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu list-menu mt-4 mb-3" },
    [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-sm float-end",
              on: { click: _vm.add },
            },
            [_vm._v("Add Light")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.items.length === 0
        ? _c(
            "div",
            { staticClass: "card card-body text-center mt-4 no-items" },
            [
              _c("h2", { staticClass: "my-4" }, [_vm._v("No items")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary btn-sm btn-sm-rel",
                  on: { click: _vm.add },
                },
                [_vm._v("Add Light")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.items, function (obj, key) {
        return _c(
          "div",
          {
            key: obj.id,
            staticClass: "card card-body p-3 my-2",
            on: {
              click: function ($event) {
                return _vm.select(obj.id)
              },
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col text-start" }, [
                _c("h2", [_vm._v(_vm._s(obj.type) + " #" + _vm._s(key))]),
                _vm._v(" "),
                _c("h6", [_vm._v(_vm._s(obj.id))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-auto" }, [
                _c("div", {
                  staticClass: "cube",
                  style: { background: obj.color_sky || obj.color },
                }),
              ]),
              _vm._v(" "),
              obj.color_ground
                ? _c("div", { staticClass: "col-auto" }, [
                    _c("div", {
                      staticClass: "cube",
                      style: { background: obj.color_ground },
                    }),
                  ])
                : _vm._e(),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Lights")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }