var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu terrain-menu mt-4 mb-3" },
    [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary btn-sm float-end",
              on: { click: _vm.onClone },
            },
            [_vm._v("Clone")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-sm float-end",
              on: { click: _vm.deselect },
            },
            [_vm._v("Back")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("ID")]),
            _vm._v(" "),
            _c("input", {
              staticClass: "form-control",
              attrs: { disabled: "" },
              domProps: { value: _vm.form.id },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Name")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.name,
                  expression: "form.name",
                },
              ],
              staticClass: "form-control",
              attrs: { required: "", disabled: !_vm.form.isNew },
              domProps: { value: _vm.form.name },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "name", $event.target.value)
                  },
                  _vm.update,
                ],
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("Scene")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.scene,
                  expression: "form.scene",
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.form.scene },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "scene", $event.target.value)
                  },
                  _vm.update,
                ],
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12 col-lg-6" },
          [
            _vm.isImage
              ? _c("div", { staticClass: "img-container row mt-4" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: { alt: "", src: _vm.form.scene },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isModel
              ? _c("model-viewer", {
                  staticClass: "model-viewer-block",
                  attrs: { "auto-rotate": "", src: _vm.form.scene },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.form.scene === ""
              ? _c("ModelUploader", {
                  staticClass: "mt-2",
                  attrs: { endpoint: "/auth/users/files" },
                  on: { uploaded: _vm.onUpload },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Position")]),
          _vm._v(" "),
          _c("Vector3", {
            on: { input: _vm.update },
            model: {
              value: _vm.form.position,
              callback: function ($$v) {
                _vm.$set(_vm.form, "position", $$v)
              },
              expression: "form.position",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Rotation")]),
          _vm._v(" "),
          _c("Vector3", {
            on: { input: _vm.update },
            model: {
              value: _vm.form.rotation,
              callback: function ($$v) {
                _vm.$set(_vm.form, "rotation", $$v)
              },
              expression: "form.rotation",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Scale")]),
          _vm._v(" "),
          _c("Vector3", {
            on: { input: _vm.update },
            model: {
              value: _vm.form.scale,
              callback: function ($$v) {
                _vm.$set(_vm.form, "scale", $$v)
              },
              expression: "form.scale",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.form.interactions
        ? _c("InteractionMenu", {
            attrs: { interactions: _vm.form.interactions },
            on: { update: _vm.updateInteractions },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-danger mt-3 btn-wide",
            on: { click: _vm.deleteItem },
          },
          [_vm._v("Delete")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Terrain")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }