<template>
	<div class="menu action-menu">
		<div class="form-group" v-if="interactionType === 'proximity'">
			<label>Activation Radius</label>
			<input
					v-model="form[interactionType].activation_radius"
					class="form-control form-control-sm"
					type="number"
			/>
		</div>
		<div class="form-group">
			<label>Action</label>
			<select type="text" class="form-control" id="action-name" v-model="form[interactionType].action_type">
				<option value="api_call">API Call</option>
				<option value="external_call">External Javascript</option>
				<option value="custom_code">Custom Code</option>
				<option value="show_message">Show Message</option>
				<option value="show_alert">Show Alert</option>
				<option value="show_web">Show Webpage</option>
				<option value="smart_contract">Smart Contract</option>
				<option value="teleport_to_metaverse">Teleport to another Metaverse</option>
				<option value="show_nft_single">Show NFT Information</option>
				<!-- <option value="show_profile">Show Profile</option> -->
				<option value="start_dialogue">Start Dialogue</option>
				<option value="rotate_object">Rotate Object</option>
				<option value="animate_object">Animate Object</option>
				<option value="show_object">Show Object</option>
				<option value="hide_object">Hide Object</option>
				<option value="hide_object_multi">Hide Object (Multiple)</option>
				<option value="show_advertisement">Show Advertisement</option>
				<option value="show_object_swap">Swap Object</option>
				<option value="join_meeting_prompt">Join Meeting</option>
				<option value="mint_nft_prompt">Mint NFT (Solana)</option>
					<option value="move_character_to">Move character to</option>
					<option value="move_character_along">Move character along</option>
					<option value="teleport_character_to">Teleport character to</option>
			</select>
		</div>

		<component
				:alpha-id="alphaId"
				:is="toComponent(form[interactionType].action_type)"
				:interaction-type="interactionType"
				:action="form"
				@save="update"
		/>


		<div class="row">
			<div class="col-6 menu ps-0">
				<div class="form-group">
					<label>On Success</label>
					<select class="form-control" v-model="form[interactionType].on_success" @change="onChange">
						<option :value="null">Nothing</option>
						<option v-for="(action,key) in $store.state.metaverse.manifest.actions" :key="key" :value="key">{{ key }} ({{ action.action_type }})</option>
					</select>
				</div>
			</div>
			<div class="col-6 menu pe-0">
				<div class="form-group">
					<label>On Error</label>
					<select class="form-control" v-model="form[interactionType].on_error" @change="onChange">
						<option :value="null">Nothing</option>
						<option v-for="(action,key) in $store.state.metaverse.manifest.actions" :key="key" :value="key">{{ key }} ({{ action.action_type }})</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import ApiCall from "../actions/ApiCall";
import MessageCall from "../actions/MessageCall";
import CustomCodeCall from "../actions/CustomCode";
import ShowWebpageCall from "../actions/ShowWebpageCall";
import SmartContractCall from "../actions/SmartContractCall";
import ShowSingleNFT from "../actions/ShowSingleNFT";
import UserDashboard from "../../../views/profile/UserDashboard";
import TeleportToMetaverse from "../actions/TeleportToMetaverse";
import StartDialogue from "../actions/StartDialogue";
import RotateObject from "../actions/RotateObject";
import AnimateObject from "../actions/AnimateObject";
import ExternalCall from "../actions/ExternalCall";
import MintNFT from "../actions/MintNFT";

import ShowObject from "../actions/ShowObject"
import HideObject from "../actions/HideObject"
import SwapObject from "../actions/SwapObject";
import ShowAlertCall from "../actions/ShowAlertCall";
// import HideObjectMulti from "../actions/HideObjectMulti"
import ShowAdvertisement from "../actions/ShowAdvertisement"
import JoinMeeting from "../actions/JoinMeeting"
import MoveTo from "../actions/MoveTo";
import MoveAlong from "../actions/MoveAlong";
import TeleportTo from "../actions/TeleportTo";

export default {
	name: "ActionMenu",
	components: {
		ShowAlertCall,
		SwapObject,
		StartDialogue,
		UserDashboard,
		ShowSingleNFT,
		SmartContractCall,
		ShowWebpageCall,
		CustomCodeCall,
		MessageCall,
		ApiCall,
		TeleportToMetaverse,
		ExternalCall,
		RotateObject,
		AnimateObject,
		ShowObject,
		HideObject,
		MintNFT,
		// HideObjectMulti,
		ShowAdvertisement,
		JoinMeeting,
		MoveTo,
		MoveAlong,
		TeleportTo,
	},
	props: {
		alphaId: {type: String},
		interactionType: {
			type: String,
			required: true,
		},
		interaction: {
			type: Object,
		}
	},
	data() {
		return {
			toggle: !this.interaction.action_type,
			firstMount: true,
			form: {},
		}
	},
	methods: {
		toComponent(option) {
			switch (option) {
				case 'api_call':
					return 'ApiCall'
				case 'show_message':
					return 'MessageCall'
				case 'external_call':
					return 'ExternalCall'
				case 'show_error':
				case 'show_alert':
					return 'ShowAlertCall'
				case 'custom_code':
					return 'CustomCodeCall'
				case 'show_web':
					return 'ShowWebpageCall'
				case 'smart_contract':
					return 'SmartContractCall'
				case 'teleport_to_metaverse':
					return 'TeleportToMetaverse'
				case 'show_nft_single':
					return 'ShowSingleNFT'
				case 'start_dialogue':
					return 'StartDialogue'
				case 'rotate_object':
					return 'RotateObject';
				case 'animate_object':
					return 'AnimateObject';
				case 'show_object':
					return 'ShowObject';
				case 'hide_object':
					return 'HideObject';
				case 'hide_object_multi':
					return 'HideObjectMulti';
				case 'show_advertisement':
					return 'ShowAdvertisement';
				case 'show_object_swap':
					return "SwapObject"
				case 'join_meeting_prompt':
					return "JoinMeeting"
				case 'mint_nft_prompt':
					return "MintNFT"
				case 'move_character_to': return 'MoveTo'
				case 'move_character_along': return 'MoveAlong'
				case 'teleport_character_to': return 'TeleportTo'
			}
		},
		onChange() {
			this.$emit("update", this.form);
		},
		update(data) {
			if (this.firstMount) {
				this.firstMount = false
				return
			}

			this.form = data
			this.$emit("update", data);
			this.toggle = !this.toggle
		},
		isAction(action) {
			return this.form[this.interactionType].action_type === action
		}
	},
	beforeMount() {
		const base = {on_success: null, on_error: null}

		if (this.interactionType === "proximity")
			base.activation_radius = 10

		this.form = {
			[this.interactionType]: Object.assign(base, this.interaction),
		}
	}
}
</script>

<style scoped>
.card-header {
	cursor: pointer;
}
</style>
