<template>
	<div class="action">

		<form class="form menu">
			
			<div class="form-group">
				<label>Spots</label>
				<input class="form-control" type="number" v-model.number="spots"/>
			</div>

			<div class="form-group">
				<label>Repeatable</label>
				<input v-model="form.repeatable" type="checkbox">
			</div>

            <div class="form-group mb-3">
                <label>Start</label>
                <Vector3
                    v-for="index in spots"
                    :key="index"
                    v-model="form.positions[index - 1]"
                ></Vector3>
                <label>End</label>
            </div>

			<div class="row mt-2">
				<div class="col-12">
					<button class="btn btn-outline-primary btn-block" @click="save">Update</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

import Vector3 from "../fields/Vector3";

export default Vue.extend({
	name: "MoveAlong",
    components: {
        Vector3,
    },
	mixins: [ actionsMixin ],
	data() {
		return {
			form: {
                repeatable: false,
				positions: [],
			},
            spots: this.action[this.interactionType].data?.positions?.length || 1,
		}
	},
    watch: {
        spots: {
            handler(newValue) {
				if (newValue < 1) return

				let tempPositions = []

                for(let i=0; i < newValue; i++) {
					if (i < this.form.positions.length)
                    	tempPositions.push(this.form.positions[i])
					else {
                    	tempPositions.push([0,0,0])
						console.log('that just happened')
					}
                }

                this.form.positions = tempPositions
            },
            // deep: true,
            // immediate: true,
        }
    },
	methods: {
		addParam: function(e) {
			e.preventDefault()
			console.log(this.form)
			this.form.params.push("");
		},

		addHeader: function(e) {
			e.preventDefault()
			console.log(this.form)

			this.$set(this.form.headers, "NEW", "")
			// this.form.headers['NEW'] = "";
		},

		addBody: function(e) {
			e.preventDefault()
			console.log(this.form)
			this.$set(this.form.body, "NEW", "")
			// this.form.body['NEW'] = "";
		},

		addResponse: function(e) {
			e.preventDefault()
			console.log(this.form)
			this.form.response.push("")
			// this.form.body['NEW'] = "";
		}
	},
})
</script>

<style scoped>

</style>
