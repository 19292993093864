<template>
	<div class="menu settings-menu mt-3 mb-3">
		<h3 class="mb-2 text-center">General Settings</h3>

		<div class="row">

			<div class="col-sm-12">
				<div class="form-group">
					<label>Metaverse Name</label>
					<input class="form-control" v-model="form.name" type="text" @input="update">
				</div>

				<div class="form-group">
					<label>Description</label>
					<textarea v-model="form.description" class="form-control" @input="update">{{form.description}}</textarea>
				</div>
			</div>
		</div>

		<div class="row mt-3" v-if="!simple">
			<div class="col-sm-12">
				<div class="form-group">
					<label class="mb-3">Banner Image</label>
					<button v-show="form.banner_src" class="btn btn-primary btn-xs float-end" @click="removeBanner">Remove</button>

					<div class="img-container banner mt-3" :style="{backgroundImage: `url(${form.banner_src})`}"
							v-show="form.banner_src">
					</div>
					<ImageUploader
							v-show="!form.banner_src"
							@uploaded="onBannerUpload"
							endpoint="/auth/users/files"
							class="mb-3"
					></ImageUploader>

				</div>
			</div>

			<div class="col-sm-12 mt-3">
				<div class="form-group">
					<div class="d-inline-block">
						<label class="">Logo</label>
						<p class="mb-0 p-0 info">Displayed on the world map</p>
					</div>
					<button v-show="form.logo_src" class="btn btn-primary btn-xs float-end" @click="removeLogo">Remove</button>

					<div class="mt-3">
						<div class="uploaded text-center" v-show="form.logo_src">
							<img class="img-fluid logo" :src="form.logo_src">
						</div>
						<ImageUploader v-show="!form.logo_src" @uploaded="onLogoUpload" class="pb-3"
													 endpoint="/auth/users/files"></ImageUploader>
					</div>
				</div>
			</div>
		</div>

		<section v-if="false">
			<hr>
			<h4 class="mb-2 text-center">Metaverse Configuration</h4>
			<div class="form-group">
				<div class="d-inline-block">
					<label>Not Safe For Work (NSFW)</label>
					<p class="info">If enabled, users will be able to create NSFW content but will not be listed to users without
						KYC verification.</p>
				</div>
				<input v-model="form.nsfw" type="checkbox">
			</div>


			<div class="form-group">
				<div class="d-inline-block">
					<label>Charge Entry</label>
					<p class="info">If enabled, users will be charged when joining the room, granting them access for the duration
						of their session.</p>
				</div>
				<input v-model="form.charge_entry" type="checkbox">
			</div>
		</section>


		<div class="form-group" v-if="form.charge_entry">
			<label>Entry Amount</label>
			<input class="form-control" v-model="form.charge_entry_amount" type="number">
		</div>
		<div class="form-group" v-if="form.charge_entry">
			<label>Entry Currency Contract</label>
			<input class="form-control" v-model="form.charge_entry_currency" type="text">
		</div>
		<div class="form-group" v-if="form.charge_entry">
			<label>Destination Wallet</label>
			<input class="form-control" v-model="form.charge_entry_dest_wallet" type="text">
		</div>

		<section id="moderation" v-if="!simple">

			<hr>
			<h4 class="mb-2 text-center">Moderation</h4>

			<div class="form-group">
				<div class="row">

					<div class="col-6">
						<label>Admins</label>
						<p class="info">Admins are able to change all settings within the metaverse with no access to the underlying
							NFT.</p>
						<p class="info warning"><strong>NOTE:</strong> Only ever grant admin access to trusted users</p>
					</div>
					<small class="col-6">
						<button class="btn btn-outline-primary btn-sm float-end" @click="addAdmin">ADD</button>
					</small>
				</div>

				<div class="row mt-3" v-for="(value,key) in form.admins" :key="key">
					<div class="col-8">
						<input class="form-control" type="text" v-model="form.admins[key]" placeholder="Public Address (Solana)"/>
					</div>
					<div class="col">
						<button class="btn btn-danger btn-block mt-2" @click="removeAdmin(key)">REMOVE</button>
					</div>
				</div>

			</div>

			<div class="form-group">
				<div class="row">
					<div class="col-6">
						<label>Moderators</label>
						<p class="info">Moderators are able to manage certain aspects of the metaverse such as chat, players &
							interactive items.</p>
					</div>
					<small class="col-6">
						<button class="btn btn-outline-primary btn-sm float-end" @click="addModerator">ADD</button>
					</small>
				</div>

				<div class="row mt-3" v-for="(value,key) in form.moderators" :key="key">
					<div class="col-8">
						<input class="form-control menu-control" type="text" v-model="form.moderators[key]"
								placeholder="Public Address (Solana)"/>
					</div>
					<div class="col">
						<button class="btn btn-danger btn-block mt-2" @click="removeModerator(key)">REMOVE</button>
					</div>
				</div>

			</div>
		</section>


		<div v-if="versions.length">
			<hr>
			<h4 class="mb-2 text-center">Versions</h4>
			<div class="row mt-3">
				<div class="col-6 offset-3">
					<p class="info info-lg my-3 text-center">Metaverse versions are stored forever on IPFS so you can always revert to a previous
						date/time.</p>

					<p class="info info-lg my-3 text-center">Loading a previous version will create a new draft with the state of the old metaverse, from
						there you can modify and save the draft to update your metaverse.</p>

					<select class="form-control-sm text-left" @change="onVersionSelect">
						<option disabled selected>Select previous version</option>
						<option v-for="(version, key) in versions" :key="version.uuid" :value="key">
							{{ new Date(version.created_at).toLocaleString() }} -
							{{ version.uuid }}
						</option>
					</select>

					<div class="row mt-3 text-left">
						<div class="col">
							<p class="small">Version: <code
									class="mx-3">{{ selectedVersion.uuid ? selectedVersion.uuid : 'No previous version selected' }}</code>
							</p>
							<p class="small">Date Saved:
								<code class="mx-3">{{
										selectedVersion.created_at ? new Date(selectedVersion.created_at).toLocaleString() : '-'
									}}</code></p>
						</div>
						<div class="col">

							<div v-if="!$store.state.wallet.connected">
								<p>Connect wallet</p>
							</div>
							<PhantomSign v-else :disabled="!selectedVersion.uuid" class="btn btn-primary" @sign="onSaveSigned">Load Previous Version</PhantomSign>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!--		<hr>-->

		<!--		modules  -->
		<!--		<div class="row mb-5">-->
		<!--			<h4 class="mb-2 text-center">Modules</h4>-->

		<!--			<div :class="`col`" v-for="(enabled,mod) in form.modules" :key="mod">-->
		<!--				<div :class="`module ${enabled ? 'enabled': ''} text-center p-2`">-->
		<!--					<i :class="`fa ${icons[mod]}`"></i>-->
		<!--					<h5 class="text-uppercase mt-2">{{ mod.replace("-", " ") }}</h5>-->
		<!--					<p>{{ enabled ? 'Enabled' : 'Disabled' }}</p>-->
		<!--				</div>-->
		<!--			</div>-->
		<!--		</div>-->
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"

import Metaverse from "../../../js/api/metaverse"

import PhantomSign from "@/components/wallet/PhantomSign";
import Phantom from "@/components/wallet/Phantom";
import ImageUploader from "../../upload/ImageUploader";
import MultiWallet from "../../wallet/MultiWallet";

export default Vue.extend({
	name: "MetaverseSettingsMenu",
	components: {
		MultiWallet,
		ImageUploader,
		PhantomSign,
		Phantom,
	},
	props: {
		simple: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			form: {
				admins: [],
				moderators: [],
				banner_src: "",
				logo_src: "",
				name: "",
				description: "",
				nsfw: false,
				charge_entry: false,
				charge_entry_amount: 0,
				charge_entry_currency: "",
				charge_entry_dest_wallet: "",
			},
			property: {
				property: 'settings',
				object: null,
			},
			versions: [],
			selectedVersion: {},
			icons: {
				"multiplayer": "fa-users",
				"chat": "fa-comments",
				"voice": "fa-microphone",
				"video": "fa-video-camera",
				"chat-history": "fa-comments",
				"chat-emoji": "fa-smile",
				"motion-capture": "fa-video-camera",
			}
		}
	},
	computed: {
		item() {
			return this.$store.state.metaverse.manifest.settings
		}
	},
	methods: {
		...mapActions({
			manifestUpdate: 'metaverse/manifestUpdate',
			getMetaverseVersion: 'metaverse/getMetaverseVersion',
			updateMetaverse: 'metaverse/updateMetaverse',
		}),

		onSaveSigned: async function(sig) {
			this.manifestUpdate({
				...this.property,
				object: this.form,
			})

			try {
				await this.getMetaverseVersion(this.selectedVersion.uuid)

				await this.updateMetaverse(sig)

				this.$toastr.s("Metaverse updated")

				location.reload()
			} catch (e) {
				console.error(e)
			}
		},

		update(e) {
			if (e)
			e.preventDefault()
			this.manifestUpdate({
				...this.property,
				object: this.form,
			})

			this.$emit("update", this.form);
		},
		addModerator(e) {
			e.preventDefault()
			this.form.moderators.push('')
		},
		addAdmin(e) {
			e.preventDefault()
			this.form.admins.push('')
		},
		removeAdmin(key) {
			this.form.admins.splice(key, 1)
					this.update()
		},
		removeModerator(key) {
			this.form.moderators.splice(key, 1)
					this.update()
		},
		onBannerUpload(bannerSrc) {
			this.$set(this.form, "banner_src", bannerSrc)
					this.update()
		},
		onLogoUpload(logoSrc) {
			this.$set(this.form, "logo_src", logoSrc)
					this.update()
		},
		removeLogo(e) {
			e.preventDefault()
			this.$set(this.form, "logo_src", "")
					this.update()
		},
		removeBanner(e) {
			e.preventDefault()
			this.$set(this.form, "banner_src", "")
					this.update()
		},

		async getMetaverseIteration() {
			const resp = await Metaverse.versions(this.$route.params.id)
			if (!resp) {
				return
			}
			this.versions = resp.data
		},

		onVersionSelect(e) {
			this.selectedVersion = this.versions[e.target.value]
		},
	},
	async beforeMount() {
		this.getMetaverseIteration().catch((e) => {
			//No versions
		})
	},
	mounted() {
		// this.$set(this, 'form', this.item)
		this.form = {
			...this.form,
			...this.item,
		}
	},
})
</script>

<style scoped>
.enabled {
	border: 1px solid green;
	background: rgba(0, 128, 0, 0.1);
	border-radius: 9px;
}

img.logo {
	max-height: 200px;
	margin-left: 5%;
	border-radius: 9px;
}

.banner {
	height: 500px;
	width: 100%;
	border-radius: 7px;
	overflow: hidden;
	background-color: rgba(255, 255, 255, 0.1);
	background-repeat: no-repeat;
	background-attachment: scroll;
	background-position: center;
	background-size: cover;
}

p {
	margin: 0;
}

select, option, code {
	font-family: monospace;
}
</style>
