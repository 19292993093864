<template>
	<div class="menu terrain-menu mt-4 mb-3">
		<div class="row">
			<div class="col">
				<h3 class="mb-2">Terrain</h3>
			</div>
			<div class="col">
				<button class="btn btn-outline-secondary btn-sm float-end" @click="onClone">Clone</button>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="deselect">Back</button>
			</div>
		</div>

		<div class="row">
			<div class="col-6">
				<div class="form-group">
					<label>ID</label>
					<input :value="form.id" disabled class="form-control">
				</div>

				<div class="form-group">
					<label>Name</label>
					<input required v-model="form.name" :disabled="!form.isNew" class="form-control" @input="update">
				</div>

				<div class="form-group">
					<label>Scene</label>
					<input v-model="form.scene" @input="update" class="form-control">
				</div>
			</div>
			<div class="col-sm-12 col-lg-6">
				<div class="img-container row mt-4" v-if="isImage">
					<img alt="" class="img-fluid" :src="form.scene">
				</div>

				<model-viewer v-if="isModel" auto-rotate class="model-viewer-block" :src="form.scene"></model-viewer>

				<ModelUploader class="mt-2" @uploaded="onUpload" v-if="form.scene === ''" endpoint="/auth/users/files"></ModelUploader>
			</div>
		</div>

		<hr>

		<div class="form-group">
			<label>Position</label>
			<Vector3 v-model="form.position" @input="update"/>
		</div>

		<div class="form-group">
			<label>Rotation</label>
			<Vector3 v-model="form.rotation" @input="update"/>
		</div>

		<div class="form-group">
			<label>Scale</label>
			<Vector3 v-model="form.scale" @input="update"/>
		</div>

		<hr>

		<InteractionMenu
				v-if="form.interactions"
				:interactions="form.interactions"
				@update="updateInteractions"
		></InteractionMenu>

		<div class="text-center">
			<!-- <button class="btn btn-primary mt-3 btn-wide" :disabled="!canGoNext" @click="update">Update</button> -->
			<button @click="deleteItem" class="btn btn-danger mt-3 btn-wide">Delete</button>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapActions} from "vuex"

import Vector3 from "../fields/Vector3";
import InteractionMenu from "../interactions/InteractionContainer";
import "@google/model-viewer"
import ModelUploader from "../../upload/ModelUploader";
import {isImage, isModel} from "../../../js/util/modelDetector";
import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";

export default Vue.extend({
	name: "TerrainMenu",
	components: {ModelUploader, InteractionMenu, Vector3},
	props: {
		meta: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			property: {
				property: 'terrain',
				object: null,
			},
			form: {
				isNew: false,
				name: "",
				scene: "",
				interactions: {
					click: {},
					proximity: {},
				},
			}
		}
	},
	computed: {
		canGoNext() {
			return this.form.name !== '' && this.form.scene !== '';
		},
		item() {
			return this.$store.state.metaverse.manifest.terrain.filter(terrain => {
				return terrain.id === this.meta
			})[0]
		},
		isModel() {
			return isModel(this.form.scene);
		},
		isImage() {
			return isImage(this.form.scene);
		}
	},
	watch: {
		item: {
			handler() {
				this.form = this.item
				if (!this.form.interactions) {
					this.form.interactions = {
						click: {},
						proximity: {},
					}
				}
			}
		}
	},
	methods: {
		...mapActions({
			manifestUpdate: 'metaverse/manifestUpdate',
			manifestAdd: 'metaverse/manifestAdd',
			manifestDelete: 'metaverse/manifestDelete',
		}),

		deselect() {
			this.$emit('select', {menu: 'terrain', item_id: null})
		},
		update() {
			if (!this.canGoNext)
				return

			this.manifestUpdate({
				...this.property,
				object: this.form,
			})

			this.$emit("update", this.form);
			this.form.isNew = false;
		},

		deleteItem() {
			this.manifestDelete({
				...this.property,
				object: this.form,
			})

			this.$emit('delete', this.form.id)
			this.deselect()
		},
		onUpload(data) {
			console.log("File uploaded", data, this.form)
			this.$set(this.form, "scene", data);
			this.$forceUpdate();
		},

		onClone(e) {
			e.preventDefault()

			const obj = Object.assign({}, this.form)
			obj.id = uuidv4()
			obj.isNew = true
			obj.data = Object.assign({}, this.form.data)
			obj.interactions = Object.assign({}, this.form.interactions)
			obj.modified = Object.assign({}, this.form.modified)

			this.manifestAdd({
				...this.property,
				object: obj,
			})

			this.$emit("add", obj);
			this.$emit('select', {menu: 'terrain', item_id: obj.id})
		},

		updateInteractions(d) {
			this.form.interactions = d

			this.update()
			// (d) => form.interactions = d
		}
	},
	beforeMount() {
		this.form = Object.assign(this.form, this.item);
		if (this.form.name === '' && this.form.isNew) {
			// this.form.name = '';
			this.form.name = `terrain-${uuidv4()}`;
		}

		if (!this.form.interactions) {
			this.form.interactions = {
				click: {},
				proximity: {},
			}
		}
	}
})
</script>

<style scoped>
.img-container img {
	max-height: 350px;
	width: auto;
	margin: auto;
}
</style>
