<template>
	<div class="menu terrain-list-menu mt-4 mb-3">
		<div class="row">
			<div class="col">
				<h3 class="mb-2">Sound</h3>
			</div>
<!--			<div class="col"><input class="form-control form-control-sm text-center search" placeholder="Search"-->
<!--															v-model="search"></div>-->
<!--			<div class="col-auto">-->
<!--			</div>-->
		</div>


		<h5 class="text-left mt-2">General Music</h5>

		<div class="row mt-2 mb-3">
			<div class="col-6">
				<div v-on:click="update('ambient_music')" class="card card-body sound-option"><h5 class="mb-4">Ambient Music</h5>
					<i class="fa fa-music fa-3x"></i>
					<i class="mt-2">{{ $store.state.metaverse.manifest.sound['ambient_music'].name }}</i>
				</div>
			</div>
			<div class="col-6">
				<div v-on:click="update('movement')" class="card card-body sound-option"><h5 class="mb-4">Movement Sound</h5>
					<i class="fa fa-music fa-3x"></i>
					<i class="mt-2">{{ $store.state.metaverse.manifest.sound['movement'].name }}</i>
				</div>
			</div>
<!--			<div class="col">-->
<!--				<div v-on:click="update('chat')" class="card card-body sound-option"><h5>Chat Notification</h5>-->
<!--					<i class="fa fa-music fa-3x mt-3"></i>-->
<!--					<i class="mt-2">{{ $store.state.metaverse.manifest.sound['chat'].name }}</i>-->
<!--				</div>-->
<!--			</div>-->
<!--			<div class="col">-->
<!--				<div v-on:click="update('voice')" class="card card-body sound-option"><h5>Voice Notification</h5>-->
<!--					<i class="fa fa-music fa-3x mt-3"></i>-->
<!--					<i class="mt-2">{{ $store.state.metaverse.manifest.sound['voice'].name }}</i>-->
<!--				</div>-->
<!--			</div>-->
		</div>

		<div class="row">
			<div class="col">
				<h5 class="text-left mt-3">Area Music</h5></div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end mx-2" disabled @click="add">Add Area Music</button>
			</div>
		</div>

		<div class="row" v-if="items.length === 0">
			<div class="col-12">
				<div class="card card-body mt-3">
					<i>No area music setup</i>
				</div>
			</div>
		</div>

		<div class="terrain card card-body p-1 my-2" v-for="(sound,key) in items" :key="sound.id"
				 v-on:click="update(sound.id)">
			<div class="row">
				<div class="col-4">
					<h4>{{ sound.name }}</h4>
					<h6>{{ sound.id }}</h6>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "SoundListMenu",
	data() {
		return {
			search: '',
		}
	},
	computed: {
		items: function () {
			let t = this.$store.state.metaverse.manifest.sound.area_music
			if (t === null) {
				return []
			}

			if (this.search !== '')
				t = t.filter(t => t.name.indexOf(this.search) > -1);

			return t;
		}
	},
	methods: {
		update(soundID) {
			this.$emit("update", soundID);
		},
		add(e) {
			e.preventDefault();
			this.$emit("add", {volume: 1, area: {tl: {x: 0, y: 0, z: 0}, br: {x: 0, y: 0, z: 0}}});
		}
	},
	mounted() {
		console.log("MSD", this.$store.state.metaverse.manifest.sound)
		console.log("SoundListMenu mounted", this.items);
	}
}
</script>

<style scoped>
.terrain {
	transition: all 0.2s ease-out;
}

h4 {
	font-size: 1em;
}

h6 {
	font-size: 0.6em;
	color: grey;
}

.terrain:hover {
	background: rgba(0, 0, 0, 0.9);
	border-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
}

.search {
	background: unset;
}

.sound-option {
	padding-top: 3%;
	height: 150px;
}
</style>
