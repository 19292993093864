<template>
	<div class="action">
		<form class="menu">
			<div class="form-group">
				<label>Code</label>
				<pre><textarea rows="10" class="form-control code">{{decoded}}</textarea></pre>
			</div>

			<div class="form-group">
				<label>Default Parameters <small><button class="btn btn-outline-light btn-sm" @click="addParam">ADD</button></small></label>

				<div class="row" v-for="(value,key) in form.params" :key="key">
					<input type="text" v-model="form.params[key]" placeholder="Default Value"/>
				</div>
				<!--				TODO Key -> Value -->
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
	name: "CustomCodeCall",
	mixins: [ actionsMixin ],
	data() {
		return {
			form: {
				code: "",
				params: []
			}
		}
	},
	computed: {
		decoded() {
			return this.form.code;
		}
	},
	methods: {
		addParam: function(e) {
			e.preventDefault()
			console.log(this.form)
			this.form.params.push("");
		},
	},
})
</script>

<style scoped>
	textarea.code {
		background: transparent;
		color: white;
		font-size: 0.8rem;
	}
</style>
