var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu terrain-menu mt-4 mb-3" }, [
    _c("div", { staticClass: "row" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary btn-sm float-end",
            on: { click: _vm.onBack },
          },
          [_vm._v("Back")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("ID")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { disabled: "" },
        domProps: { value: _vm.form.id },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Name")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        domProps: { value: _vm.form.name },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Volume")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-2" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.volume,
                expression: "form.volume",
              },
            ],
            staticClass: "form-control form-control-sm",
            attrs: { readonly: "" },
            domProps: { value: _vm.form.volume },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "volume", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.volume,
                expression: "form.volume",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control",
            attrs: { type: "range", min: "0", max: "100" },
            domProps: { value: _vm.form.volume },
            on: {
              __r: function ($event) {
                _vm.$set(_vm.form, "volume", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("h5", { staticClass: "text-left" }, [_vm._v("Select Track")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-12" },
      [
        _c("UserNFTCollection", {
          attrs: { active: _vm.form.name, filter: "audio/mp3" },
          on: { add: _vm.musicSelect },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "text-center" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-primary mt-3 btn-wide",
          on: { click: _vm.update },
        },
        [_vm._v("Update")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-danger mt-3 btn-wide",
          on: {
            click: function ($event) {
              return _vm.$emit("delete", _vm.form.id)
            },
          },
        },
        [_vm._v("Delete")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Sound")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }