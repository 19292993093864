<template>
	<div class="action menu">
		<form class="menu">
			<div class="form-group">
				<label>Title</label>
				<input v-model="form.title" class="form-control">
			</div>

			<div class="form-group">
				<label>Message</label>
				<input v-model="form.message" class="form-control">
			</div>

			<div class="form-group">
				<label>Background Color</label>
				<input v-model="form.backgroundColor" type="color" class="form-control">
			</div>

			<div class="form-group">
				<label>Text Color</label>
				<input v-model="form.textColor" type="color" class="form-control">
			</div>

			<div class="form-group">
				<label>Center Offset</label>
				<Vector3 v-model="form.center_offset"/>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'
import Vector3 from "../fields/Vector3";

export default Vue.extend({
	name: "MessageCall",
	components: {Vector3},
	mixins: [actionsMixin],
	data() {
		return {
			form: {
				title: "",
				message: "",
				backgroundColor: "",
				textColor: "",
				center_offset: [],
				params: []
			}
		}
	},
})
</script>

<style scoped>

</style>
