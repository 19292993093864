<template>
	<div class="menu entity-menu">
		<div class="row mt-3">
			<div class="col">
				<h3 class="mb-2">Model</h3>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="deselect">Back</button>
			</div>
		</div>

		<div class="row">
			<div class="col-sm-12 col-lg-6">
				<div class="form-group">
					<label>Unique Name</label>
					<input @input="onInput" v-model="form.id" :disabled="form.scene || form.scene !== ''" class="form-control">
				</div>

				<div class="form-group">
					<label>File</label>
					<input v-model="form.scene" disabled class="form-control">
				</div>

				<div class="form-group mt-3">
					<i>Custom animations coming soon!</i>
				</div>

				<div class="info-container mt-5">
					<i>Free & Paid 3D models can be found online through a variety of vendors</i>

					<div class="col-12 mt-3 text-center">
						<a href="https://turbosquid.com" target="_blank"><img class="sponsor-img img-sm me-3" alt="TurboSquid"
																																	src="/assets/images/turbo_squid.png"></a>
						<a href="https://sketchfab.com" target="_blank"><img class="sponsor-img img-sm me-3" alt="Sketchfab"
																																 src="/assets/images/sketchfab.svg"></a>
					</div>
				</div>
			</div>
			<div class="col-sm-12 col-lg-6">
				<div class="img-container row">
					<img class="img-fluid" v-if="isImage" :src="form.scene">
				</div>

				<model-viewer v-if="isModel" auto-rotate class="model-viewer-block" :src="form.scene"></model-viewer>

				<ModelUploader class="uploader" @uploaded="onUpload" v-if="form.scene === ''"
											 endpoint="/auth/users/files"></ModelUploader>
			</div>
		</div>

		<div class="text-center">
			<hr>
			<button class="btn btn-primary mt-3 btn-wide" :disabled="!canGoNext" @click="update">Update</button>
			<button @click="deleteItem" class="btn btn-danger mt-3 btn-wide">Delete</button>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"

import "@google/model-viewer"
import ModelUploader from "../../upload/ModelUploader";
import {isImage, isModel} from "../../../js/util/modelDetector";

export default Vue.extend({
	name: "ModelMenu",
	components: {ModelUploader},
	props: {
		meta: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			property: {
				property: 'model',
				object: null,
			},
			form: {
				scene: '',
			}
		}
	},
	computed: {
		canGoNext() {
			return this.form.name !== '' && this.form.scene !== '';
		},
		item() {
			return this.$store.state.metaverse.manifest.models[this.meta];
		},
		isModel() {
			return isModel(this.form.scene);
		},
		isImage() {
			return isImage(this.form.scene);
		}
	},
	methods: {
		...mapActions({
			manifestUpdate: 'metaverse/manifestUpdate',
			manifestDelete: 'metaverse/manifestDelete',
		}),

		deselect() {
			this.$emit('select', {menu: 'models', item_id: null})
		},

		deleteItem() {
			this.manifestDelete({
				...this.property,
				object: this.form,
			})

			this.$emit('delete', this.form.id)
			this.deselect()
		},

		update(e) {
			e.preventDefault()

			if (!this.canGoNext)
				return

			this.manifestUpdate({
				...this.property,
				object: this.form,
			})

			this.$emit("update", this.form);
			this.deselect()
		},

		onUpload(data, wallet, file) {
			console.log("File uploaded", data, file)
			// this.form.id = file.name.replace(".glb", "").replace(".", "_")
			this.$set(this.form, "scene", data); //TODO
			this.$set(this.form, "id", file.uid); //TODO
		},
		onInput() {
			this.form.id = this.form.id.replace(" ", "_").toLowerCase()
		}
	},
	beforeMount() {
		this.form = {
			id: this.meta,
			scene: typeof this.item === 'object' ? this.item.scene : this.item === undefined ? '' : this.item
		}
	}
})
</script>

<style scoped>
model-viewer {
	height: 100%;
	width: 100%;
}

.img-container img {
	max-height: 350px;
	width: auto;
	margin: auto;
}

.uploader {
	height: auto;
}
</style>
