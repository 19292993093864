<template>
	<div class="light spot-light menu">
<!--		<div class="form-group">-->
<!--			<label>Light Type</label>-->
<!--			<input class="form-control" readonly v-model="form.type">-->
<!--		</div>-->

		<div class="form-group">
			<label>Color</label>
			<input class="form-control" type="color" :value="form.color" @change="(e) => form.color = e.target.value">
		</div>

		<div class="form-group">
			<label>Distance</label>
			<input class="form-control" type="number" min="0.0" v-model.number="form.distance" step="0.1">
		</div>

		<div class="form-group">
			<label>Intensity</label>
			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" step="0.1"  v-model.number="form.intensity">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.intensity" min="0.0" max="2.0" step="0.01">
				</div>
			</div>
		</div>

		<div class="form-group">
			<label>Angle</label>
			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0.0" :max="Math.PI/2" v-model="form.angle" step="0.1">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.angle" min="0.0" :max="Math.PI/2" step="0.1">
				</div>
			</div>
		</div>

		<div class="form-group">
			<label>Decay</label>
			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" step="0.1"  v-model.number="form.decay">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.decay" min="0.0" max="2.0" step="0.01">
				</div>
			</div>
		</div>

		<div class="form-group">
			<label>Penumbra</label>
			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" step="0.1"  v-model.number="form.penumbra">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.penumbra" min="0.0" max="1.0" step="0.01">
				</div>
			</div>
		</div>

<!--		<div class="form-group">-->
<!--			<label>Focus</label>-->
<!--			<div class="row">-->
<!--				<div class="col-4">-->
<!--					<input class="form-control menu-control" type="number" min="0" step="0.1"  v-model.number="form.focus">-->
<!--				</div>-->
<!--				<div class="col-8">-->
<!--					<input class="btn-block mt-2" type="range" v-model="form.focus" min="0.0" max="1.0" step="0.01">-->
<!--				</div>-->
<!--			</div>-->
<!--		</div>-->

		<hr>
		<div class="form-group">
			<label>Position</label>
			<Vector3 v-model="form.position" />
		</div>

		<div class="form-group">
			<label>Target</label>
			<Vector3 v-model="form.target" />
		</div>

		<hr>

		<div class="form-group">
			<label>Shadow Enabled</label>
			<input type="checkbox" v-model="form.shadow.enabled">
		</div>

		<div class="form-group" v-show="form.shadow.enabled">
			<label>Shadow Bias</label>
			<div class="row mt-2">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="-1" step="0.0001"  v-model.number="form.shadow.bias">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.shadow.bias" min="-1" max="0.1" step="0.001">
				</div>
			</div>
		</div>

<!--		<div class="form-group" v-show="form.shadow.enabled">-->
<!--			<label>Shadow Radius</label>-->
<!--			<select class="form-control">-->
<!--				<option :value="512">Low</option>-->
<!--				<option :value="1024">Medium</option>-->
<!--				<option :value="2048">High</option>-->
<!--				<option :value="4096">Very High</option>-->
<!--			</select>-->
<!--		</div>-->


		<div class="form-group" v-show="form.shadow.enabled">
			<label>Start</label>
			<input type="number" :value="form.shadow.near" class="form-control" min="0" step="0.1">
		</div>
		<div class="form-group" v-show="form.shadow.enabled">
			<label>End</label>
			<input type="number" :value="form.shadow.far" class="form-control" min="0" step="0.1">
		</div>
	</div>
</template>

<script>
import Vector3 from "../fields/Vector3";

export default {
	name: "SpotLight",
	components: {Vector3},
	props: {
		light: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			form: {
				type: "spot",
				color: "#ffffff",
				intensity: 1,
				distance: 0,
				penumbra: 1,
				angle: 0,
				decay:1,
				position: [0, 0, 0],
				target: [0, 0, 0],
				shadow: {
					enabled: true,
					bias: -0.003,
					near: 0.5,
					far: 500
				}
			},
		}
	},
	watch: {
		form: {
			handler: function(form) {
				this.$emit("update", form);
			},
			deep: true
		}
	},
	beforeMount() {
		console.log("Mounting SpotLight", this.light)
		this.form = this.light
		// this.form.color = this.form.color.replace("0x", "#");
	}
}
</script>

<style scoped>

</style>
