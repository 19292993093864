<template>
	<div class="light ambient-light menu">
<!--		<div class="form-group">-->
<!--			<label>Light Type</label>-->
<!--			<input class="form-control" readonly v-model="form.type">-->
<!--		</div>-->

		<div class="form-group">
			<label>Sky Color</label>
			<input class="form-control" type="color" v-model="form.color_sky" @input="$emit('update', form)" >
		</div>

		<div class="form-group">
			<label>Ground Color</label>
			<input class="form-control" type="color" v-model="form.color_ground" @input="$emit('update', form)" >
		</div>

		<div class="form-group">
			<label>Intensity</label>
			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" max="1" step="0.1"  v-model.number="form.intensity" @input="$emit('update', form)" >
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.intensity" min="0.0" max="1.0" step="0.01" @input="$emit('update', form)" >
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "HemisphereLight",
	props: {
		light: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			form: {
				type: "hemisphere",
				color_sky: "#ffffff",
				color_ground: "#ffffff",
				intensity: 1
			},
		}
	},
	watch: {
		light: {
			handler(newValue) {
				this.form = newValue
			}
		},
	},
	beforeMount() {
		this.form = this.light
		this.form.color_sky = this.form.color_sky.replace("0x", "#");
		this.form.color_ground = this.form.color_ground.replace("0x", "#");
	}
}
</script>

<style scoped>

</style>
