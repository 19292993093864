var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu list-menu" },
    [
      _c("div", { staticClass: "row mt-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search",
              },
            ],
            staticClass: "form-control form-control-sm py-2 mt-0",
            attrs: { placeholder: "Search" },
            domProps: { value: _vm.search },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.search = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-auto" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-sm float-end",
              on: { click: _vm.add },
            },
            [_vm._v("Add")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.items.length === 0
        ? _c(
            "div",
            { staticClass: "card card-body text-center mt-4 no-items" },
            [
              _c("h2", { staticClass: "my-4" }, [_vm._v("No items")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary btn-sm btn-sm-rel",
                  on: { click: _vm.add },
                },
                [_vm._v("Add Entity")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.filteredItems, function (obj, key) {
        return _c(
          "div",
          {
            key: `${obj.id}${key}`,
            staticClass: "card card-body p-1 my-2 text-start",
            on: {
              click: function ($event) {
                return _vm.select(obj.id)
              },
            },
          },
          [
            _c("h4", [
              _vm._v(
                _vm._s(obj.name ? obj.name : obj.id.substr(28)) +
                  " - " +
                  _vm._s(obj.data.model.substr(0, obj.data.model.length - 37))
              ),
            ]),
            _vm._v(" "),
            _c("h6", [_vm._v(_vm._s(obj.id))]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Entities")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }