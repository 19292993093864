var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu lighting-menu mt-4 mb-3" },
    [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-sm float-end",
              on: { click: _vm.deselect },
            },
            [_vm._v("Back")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("ID")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { disabled: "" },
          domProps: { value: _vm.form.id },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Light Type")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.type,
                expression: "form.type",
              },
            ],
            staticClass: "form-control",
            on: {
              change: [
                function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.form,
                    "type",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                },
                _vm.lightTypeChange,
              ],
            },
          },
          [
            _c("option", { attrs: { value: "ambient" } }, [_vm._v("Ambient")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "spot" } }, [_vm._v("Spot")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "point" } }, [_vm._v("Point")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "hemisphere" } }, [
              _vm._v("Hemisphere"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { value: "rectarea" } }, [_vm._v("Plane")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.form.type === "ambient"
        ? _c("AmbientLight", {
            attrs: { light: _vm.form },
            on: { update: _vm.onLightUpdate },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.form.type === "spot"
        ? _c("SpotLight", {
            attrs: { light: _vm.form },
            on: { update: _vm.onLightUpdate },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.form.type === "point"
        ? _c("PointLight", {
            attrs: { light: _vm.form },
            on: { update: _vm.onLightUpdate },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.form.type === "hemisphere"
        ? _c("HemisphereLight", {
            attrs: { light: _vm.form },
            on: { update: _vm.onLightUpdate },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.form.type === "rectarea"
        ? _c("PlaneLight", {
            attrs: { light: _vm.form },
            on: { update: _vm.onLightUpdate },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-danger mt-3 btn-wide",
            on: { click: _vm.deleteItem },
          },
          [_vm._v("Delete")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Lighting")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }