<template>
	<form class="menu">
		<div class="form-group">
			<label>Object</label>
			<input v-model="form.target_id" class="form-control"/>
		</div>
		<div class="form-group" hidden>
			<label>Advert Screen</label>
			<input v-model="form.advert_screen_id" class="form-control"/>
		</div>
		<div class="form-group">
			<label>Duration</label>
			<input v-model="form.duration" class="form-control"/>
		</div>
		<div class="form-group">
			<label>Media <small><i>(One URL Per Line)</i></small></label>
			<textarea rows="5" v-model="mediaItems" class="form-control"/>
		</div>
		<div class="form-group">
			<label>Mesh Names</label>

			<div class="row mb-2" v-for="(obj, mesh) in form.mesh_names" :key="mesh">
				<div class="col-5 ps-0">
					<label class="col-form-label col-sm-auto green">Name:</label>
					<input readonly :value="mesh" class="form-control"/>
				</div>
				<div class="col row px-0">
					<div class="col-sm-12 col-md-6 row">
						<label class="col-form-label col-sm-auto green">X:</label>
						<div class="col-sm-10 px-0">
							<input class="form-control" min="1" type="number" step="1" v-model.number="form.mesh_names[mesh].x">
						</div>
					</div>

					<div class="col-sm-12 col-md-6 row">
						<label class="col-form-label col-sm-auto red">Y:</label>
						<div class="col-sm-10 px-0">
							<input class="form-control" min="1" type="number" step="1" v-model.number="form.mesh_names[mesh].y">
						</div>
					</div>
				</div>
				<div class="col-auto px-0">
					<button @click="removeMesh(mesh)" class="btn btn-danger btn-sm py-0 mt-5">X</button>
				</div>
			</div>

			<div class="input-group">
				<input v-model="newMeshName" class="form-control my-0" placeholder="Mesh Name"/>
				<button @click="addMesh" class="btn btn-primary">ADD</button>
			</div>

		</div>
	</form>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
	name: 'ShowAdvertisement',
	mixins: [actionsMixin],
	data() {
		return {
			mediaItems: "",
			newMeshName: "",
			form: {
				target_id: null,
				duration: 3000,
				media: [],
				mesh_names: {}
			},
		}
	},
	watch: {
		mediaItems: function () {
			const m = this.mediaItems.split("\n")
			console.log("M", this.mediaItems, m)
			if (m.length > 1)
				this.$set(this.form, "media", m)
			else
				this.$set(this.form, "media", [m])
		}
	},
	methods: {
		addMesh: function (e) {
			e.preventDefault()
			if (this.newMeshName.length <= 0)
				return

			if (!this.form.mesh_names)
				this.$set(this.form, "mesh_names", {})

			this.$set(this.form.mesh_names, this.newMeshName, {x: 1, y: 1})
			this.newMeshName = '';
		},
		removeMesh: function (mesh) {
			this.$delete(this.form.mesh_names, mesh)
		}
	},
	mounted() {
		this.mediaItems = this.form.media.join("\n")
	}
})
</script>

<style lang="scss" scoped>
.form-control {
	font-size: 0.8rem;
	background: unset;
	color: white;
	text-align: left;
}
</style>
