<template>
	<div class="menu list-menu">
		<div class="row mt-3">
			<div class="col">
				<h3 class="mb-2">Characters</h3>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="add()">Add Character</button>
			</div>
		</div>

		<div class="card card-body text-center mt-4 no-items" v-if="Object.keys(items).length === 0">
			<h2 class="my-4">No items</h2>
			<button class="btn btn-outline-primary btn-sm btn-sm-rel" @click="add()">Add Custom Character</button>
			<button class="btn btn-outline-secondary mt-3 btn-sm btn-sm-rel" @click="addDefault">Add Default Character</button>
		</div>

		<div class="card card-body p-1 my-2" v-for="obj in items" :key="obj.id"
				 @click="select(obj.id)">
			<div class="row">
				<div class="col text-center">
					<h4>{{ obj.device_type }}</h4>
				</div>
				<div class="col">
					<h4>{{ obj.name }}</h4>
					<h6>{{ obj.summary }}</h6>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"

import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";

export default Vue.extend({
	name: "CharacterListMenu",
	data() {
		return {
			property: {
				property: 'character',
				object: null,
			},
		}
	},
	computed: {
		items: function () {
			return this.$store.state.metaverse.manifest.characters
		}
	},
	methods: {
		...mapActions({
			manifestAdd: 'metaverse/manifestAdd',
		}),

		select(id) {
			this.$emit('select', {menu: 'characters', item_id: id})
		},

		add(custom) {
			const newCharacter = custom ?? {
				id: uuidv4(),
				name: uuidv4(),
				device_type: "3D3RD",
				animation_map: {
					"walk": "Walking",
					"idle": "Idle"
				}
			}

			this.manifestAdd({
				...this.property,
				object: newCharacter,
			})

			this.$emit("add", newCharacter)
			this.select(newCharacter.id)
		},
		addDefault() {
			const uuid = uuidv4()
			this.add({
				id: uuid,
				name: uuid,
				device_type: "3D3RD",
				model: "default_sprite",
				animation_map: {
					"walk": "Walking",
					"idle": "Idle"
				}
			})
		}
	}
})
</script>

<style scoped>
.card {
	transition: all 0.2s ease-out;
}

h4 {
	font-size: 0.8em;
}

h6 {
	font-size: 0.6em;
	color: grey;
}

.card:hover {
	background: rgba(0, 0, 0, 0.9);
	border-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
}
</style>
