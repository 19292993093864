<template>
    <div>
        <h5>Start Dialogue</h5>
        <div class="form-group">
            <label>Dialogue</label>
            <input
                v-model="form.uuid"
                class="form-control"
            />
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
    name: 'StartDialogue',
	mixins: [ actionsMixin ],
    data() {
        return {
            form: {
                uuid: null,
            },
        }
    },
})
</script>

<style lang="scss" scoped>
.form-control {
	font-size: 0.8rem;
	background: unset;
	color: white;
	text-align: left;
}
</style>
