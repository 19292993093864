var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "light ambient-light menu" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Color")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "color" },
        domProps: { value: _vm.form.color },
        on: { change: (e) => (_vm.form.color = e.target.value) },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Intensity")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.intensity,
                expression: "form.intensity",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control menu-control",
            attrs: { type: "number", min: "0", step: "0.1" },
            domProps: { value: _vm.form.intensity },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "intensity", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-8" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.intensity,
                expression: "form.intensity",
                modifiers: { number: true },
              },
            ],
            staticClass: "btn-block mt-2",
            attrs: { type: "range", min: "0.0", max: "1.0", step: "0.01" },
            domProps: { value: _vm.form.intensity },
            on: {
              __r: function ($event) {
                _vm.$set(_vm.form, "intensity", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }