import { render, staticRenderFns } from "./AmbientLight.vue?vue&type=template&id=3fcbcb9e&scoped=true&"
import script from "./AmbientLight.vue?vue&type=script&lang=js&"
export * from "./AmbientLight.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fcbcb9e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/build/repo/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3fcbcb9e')) {
      api.createRecord('3fcbcb9e', component.options)
    } else {
      api.reload('3fcbcb9e', component.options)
    }
    module.hot.accept("./AmbientLight.vue?vue&type=template&id=3fcbcb9e&scoped=true&", function () {
      api.rerender('3fcbcb9e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/editor/lights/AmbientLight.vue"
export default component.exports