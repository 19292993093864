<template>
	<div class="menu character-menu">
		<div class="row mt-3">
			<div class="col">
				<h3 class="mb-2">Character</h3>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="deselect">Back</button>
			</div>
		</div>


		<div class="col-12" v-if="$store.state.metaverse.manifest.models[form.model]">
			<model-viewer class="viewer" auto-rotate style="width: 100%" :src="$store.state.metaverse.manifest.models[form.model]"></model-viewer>
		</div>

		<div class="form-group">
			<label>ID</label>
			<input :value="form.id" disabled class="form-control">
		</div>

		<div class="form-group">
			<label>Name</label>
			<input v-model="form.name" class="form-control" @input="update">
		</div>

		<div class="form-group">
			<label>Summary</label>
			<input v-model="form.summary" class="form-control" @input="update">
		</div>

		<div class="form-group">
			<label>Model</label>
			<select class="form-control" v-model="form.model" @update="update">
				<option v-for="(model,key) in $store.state.metaverse.manifest.models" :value="key" :key="key">{{ key }}</option>
			</select>
		</div>

		<div class="form-group">
			<label>Device Type</label>
			<select class="form-control" @update="update" v-model="form.device_type">
				<option value="3D">1st Person</option>
				<option value="3D3RD">3rd Person</option>
				<option value="OBSERVER">Free Movement</option>
			</select>
		</div>

		<div class="form-group mb-3">
			<label>Scale</label>
			<Vector3 v-model="form.scale" @input="update"/>
		</div>

		<div class="text-center">
			<hr>
			<button @click="deleteItem" class="btn btn-danger mt-3 btn-wide">Delete</button>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"

import Vector3 from "../fields/Vector3";

export default Vue.extend({
	name: "CharacterMenu",
	components: {
		Vector3
	},
	props: {
		meta: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			property: {
				property: 'character',
				object: null,
			},
			form: {}
		}
	},
	computed: {
		item() {
			return this.$store.state.metaverse.manifest.characters.filter(d => {
				return d.id === this.meta
			})[0]
		}
	},
	methods: {
		...mapActions({
			manifestUpdate: 'metaverse/manifestUpdate',
			manifestDelete: 'metaverse/manifestDelete',
		}),

		deselect() {
			this.$emit('select', {menu: 'characters', item_id: null})
		},

		update() {
			this.manifestUpdate({
				...this.property,
				object: this.form,
			})

			this.$emit("update", this.form);
		},

		deleteItem(e) {
			e.preventDefault()
			this.manifestDelete({
				...this.property,
				object: this.form,
			})

			this.$emit('delete', this.form.id)
			this.deselect()
		},
	},
	beforeMount() {
		this.form = Object.assign({}, this.item);
		console.log("Form", this.form);
	}
})
</script>

<style scoped>
.menu > .form-group {
	border: unset;
	background: unset;
	text-align: left;
	padding: 0;
	font-size: 0.8em;
}

.menu > .form-group > .form-control {
	font-size: 0.8em;
	background: unset;
	color: white;
	text-align: left;
}

.menu > .form-group > select option {
	background: black;
	color: white;
}
</style>
