<template>
	<div class="light ambient-light menu">
<!--		<div class="form-group">-->
<!--			<label>Light Type</label>-->
<!--			<input class="form-control" readonly v-model="form.type">-->
<!--		</div>-->

		<div class="form-group">
			<label>Color</label>
			<input class="form-control" type="color" v-model="form.color" @input="$emit('update', form)">
		</div>

		<div class="form-group">
			<label>Distance</label>
			<input class="form-control" type="number" min="0.0" v-model.number="form.distance" @input="$emit('update', form)" step="0.1">
		</div>

		<div class="form-group">
			<label>Intensity</label>
			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" max="1" step="0.1" v-model.number="form.intensity" @input="$emit('update', form)">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.intensity" @input="$emit('update', form)" min="0.0" max="1.0" step="0.01">
				</div>
			</div>
		</div>

		<div class="form-group">
			<label>Decay</label>
			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" max="1" step="0.1"  v-model.number="form.decay" @input="$emit('update', form)">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.decay" @input="$emit('update', form)" min="0.0" max="1.0" step="0.01">
				</div>
			</div>
		</div>

		<hr>
		<div class="form-group">
			<label>Position</label>
			<Vector3 v-model="form.position" @input="$emit('update', form)" />
		</div>

		<hr>

		<div class="form-group">
			<label>Shadow Enabled</label>
			<input type="checkbox" v-model="form.shadow.enabled" @change="$emit('update', form)">
		</div>

		<div class="form-group" v-show="form.shadow.enabled">
			<label>Shadow Bias</label>
			<div class="row mt-2">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="-1" step="0.0001" v-model.number="form.shadow.bias" @input="$emit('update', form)">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.shadow.bias" @input="$emit('update', form)" min="-1" max="0.1" step="0.001">
				</div>
			</div>
		</div>

<!--		<div class="form-group" v-show="form.shadow.enabled">-->
<!--			<label>Shadow Radius</label>-->
<!--			<select class="form-control">-->
<!--				<option :value="512">Low</option>-->
<!--				<option :value="1024">Medium</option>-->
<!--				<option :value="2048">High</option>-->
<!--				<option :value="4096">Very High</option>-->
<!--			</select>-->
<!--		</div>-->


		<div class="form-group" v-show="form.shadow.enabled">
			<label>Start</label>
			<input type="number" v-model="form.shadow.near" class="form-control" min="0" step="0.1">
		</div>
		<div class="form-group" v-show="form.shadow.enabled">
			<label>End</label>
			<input type="number" v-model="form.shadow.far" class="form-control" min="0" step="0.1">
		</div>
	</div>
</template>

<script>
import Vector3 from "../fields/Vector3";

export default {
	name: "PointLight",
	components: {Vector3},
	props: {
		light: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			form: {
				type: "spot",
				color: "#ffffff",
				intensity: 1,
				decay: 1,
				distance: 0,
				position: [0, 0, 0],
				shadow: {
					enabled: true,
					bias: -0.003,
					near: 0.5,
					far: 500
				}
			},
		}
	},
	watch: {
		light: {
			handler(newValue) {
				this.form = newValue
			}
		},
	},
	beforeMount() {
		this.form = this.light
	}
}
</script>

<style scoped>

</style>
