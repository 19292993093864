<template>
	<div class="action menu">
		<form class="menu">
			<div class="row mt-2">

				<div class="col-12">
					<div class="form-group">
						<label>URL</label>
						<input v-model="form.url" class="form-control">
					</div>
				</div>

				<div class="row">
					<div class="col-6 menu">
						<div class="form-group">
							<label>On Success</label>
							<select class="form-control" v-model="action.on_success">
								<option :value="null">Nothing</option>
								<option v-for="(action,key) in $store.state.metaverse.manifest.actions" :key="key">{{ key }} ({{
										action.action_type
									}})
								</option>
							</select>
						</div>
					</div>
					<div class="col-6 menu">
						<div class="form-group">
							<label>On Error</label>
							<select class="form-control" v-model="action.on_error">
								<option :value="null">Nothing</option>
								<option v-for="(action,key) in $store.state.metaverse.manifest.actions" :key="key">{{ key }} ({{
										action.action_type
									}})
								</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
	name: "ShowWebpageCall",
	mixins: [ actionsMixin ],
	data() {
		return {
			form: {
				url: ""
			}
		}
	},
})
</script>

<style scoped>

</style>
