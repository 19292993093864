var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "menu entity-menu" }, [
    _c("div", { staticClass: "row mt-3" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary btn-sm float-end",
            on: { click: _vm.deselect },
          },
          [_vm._v("Back")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 col-lg-6" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Unique Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.id,
                expression: "form.id",
              },
            ],
            staticClass: "form-control",
            attrs: { disabled: _vm.form.scene || _vm.form.scene !== "" },
            domProps: { value: _vm.form.id },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "id", $event.target.value)
                },
                _vm.onInput,
              ],
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("File")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.scene,
                expression: "form.scene",
              },
            ],
            staticClass: "form-control",
            attrs: { disabled: "" },
            domProps: { value: _vm.form.scene },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "scene", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _vm._m(2),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-sm-12 col-lg-6" },
        [
          _c("div", { staticClass: "img-container row" }, [
            _vm.isImage
              ? _c("img", {
                  staticClass: "img-fluid",
                  attrs: { src: _vm.form.scene },
                })
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.isModel
            ? _c("model-viewer", {
                staticClass: "model-viewer-block",
                attrs: { "auto-rotate": "", src: _vm.form.scene },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.form.scene === ""
            ? _c("ModelUploader", {
                staticClass: "uploader",
                attrs: { endpoint: "/auth/users/files" },
                on: { uploaded: _vm.onUpload },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "text-center" }, [
      _c("hr"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary mt-3 btn-wide",
          attrs: { disabled: !_vm.canGoNext },
          on: { click: _vm.update },
        },
        [_vm._v("Update")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-danger mt-3 btn-wide",
          on: { click: _vm.deleteItem },
        },
        [_vm._v("Delete")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Model")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "form-group mt-3" }, [
      _c("i", [_vm._v("Custom animations coming soon!")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "info-container mt-5" }, [
      _c("i", [
        _vm._v(
          "Free & Paid 3D models can be found online through a variety of vendors"
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 mt-3 text-center" }, [
        _c(
          "a",
          { attrs: { href: "https://turbosquid.com", target: "_blank" } },
          [
            _c("img", {
              staticClass: "sponsor-img img-sm me-3",
              attrs: {
                alt: "TurboSquid",
                src: "/assets/images/turbo_squid.png",
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "https://sketchfab.com", target: "_blank" } },
          [
            _c("img", {
              staticClass: "sponsor-img img-sm me-3",
              attrs: { alt: "Sketchfab", src: "/assets/images/sketchfab.svg" },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }