var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu terrain-list-menu mt-4 mb-3" },
    [
      _c("div", { staticClass: "row" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col-md" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-sm btn-sm-rel",
              on: { click: _vm.add },
            },
            [_vm._v("Add Terrain")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-md" }, [
          _c(
            "button",
            {
              class: `btn btn-outline-${
                _vm.eventsOnly ? "success" : "secondary"
              } btn-sm btn-sm-rel`,
              on: { click: _vm.toggleEvents },
            },
            [_vm._v("Events Only\n\t\t\t\t")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.items.length === 0
        ? _c(
            "div",
            { staticClass: "card card-body text-center mt-4 no-items" },
            [
              _c("h2", { staticClass: "my-4" }, [_vm._v("No items")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary btn-sm btn-sm-rel",
                  on: { click: _vm.add },
                },
                [_vm._v("Add Terrain")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.items, function (terrain) {
        return _c(
          "div",
          {
            key: terrain.id,
            staticClass: "terrain card card-body p-1 my-2",
            on: {
              click: function ($event) {
                return _vm.select(terrain.id)
              },
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col text-start" }, [
                _c("h2", [
                  _vm._v(
                    _vm._s(terrain.name.substr(0, 20)) +
                      _vm._s(terrain.name.length > 20 ? "..." : "")
                  ),
                ]),
                _vm._v(" "),
                _c("h6", [_vm._v(_vm._s(terrain.id))]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-auto text-center" }, [
                _c("h4", [_vm._v("Events")]),
                _vm._v(" "),
                _c("h6", [
                  _vm._v(
                    _vm._s(
                      terrain.interactions
                        ? Object.keys(terrain.interactions).length
                        : 0
                    )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-auto text-center" }, [
                _c("h4", [_vm._v("Size")]),
                _vm._v(" "),
                _c("h6", [
                  _vm._v(_vm._s(terrain.size ? terrain.size + " kb" : "-")),
                ]),
              ]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-md" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Terrain")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }