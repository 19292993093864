var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu entity-menu" },
    [
      _c("div", { staticClass: "row mt-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-secondary btn-sm float-end",
              on: { click: _vm.onClone },
            },
            [_vm._v("Clone")]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-sm float-end",
              on: { click: _vm.deselect },
            },
            [_vm._v("Back")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("ID")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { disabled: "" },
          domProps: { value: _vm.form.id },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Name")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.name,
              expression: "form.name",
            },
          ],
          staticClass: "form-control",
          domProps: { value: _vm.form.name },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "name", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _vm.form.class === "StaticObject"
        ? _c("StaticObject", {
            attrs: {
              "alpha-id": _vm.form.id,
              data: _vm.form.data,
              interactions: _vm.form.interactions,
            },
            on: {
              updateEntity: _vm.updateEntity,
              updateInteractions: _vm.updateInteractions,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-danger mt-3 btn-wide",
            on: { click: _vm.deleteItem },
          },
          [_vm._v("Delete")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Interactables")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }