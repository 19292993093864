var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "action" }, [
    _c("form", { staticClass: "form menu" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Method")]),
        _vm._v(" "),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.method,
                expression: "form.method",
              },
            ],
            staticClass: "form-control",
            on: {
              change: function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.form,
                  "method",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
            },
          },
          [
            _c("option", { attrs: { value: "GET" } }, [_vm._v("GET")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "POST" } }, [_vm._v("POST")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "PUT" } }, [_vm._v("PUT")]),
            _vm._v(" "),
            _c("option", { attrs: { value: "DELETE" } }, [_vm._v("DELETE")]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("URL")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.url,
              expression: "form.url",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "text", placeholder: "URL" },
          domProps: { value: _vm.form.url },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "url", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [
            _vm._v("Headers "),
            _c("small", [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-light btn-sm",
                  on: { click: _vm.addHeader },
                },
                [_vm._v("ADD")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.form.headers, function (value, key) {
            return _c("div", { key: key, staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("input", {
                  staticClass: "form-control menu-control",
                  attrs: { type: "text", placeholder: "Header" },
                  domProps: { value: key },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.headers[key],
                      expression: "form.headers[key]",
                    },
                  ],
                  staticClass: "form-control menu-control",
                  attrs: { type: "text", placeholder: "Value" },
                  domProps: { value: _vm.form.headers[key] },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form.headers, key, $event.target.value)
                    },
                  },
                }),
              ]),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [
            _vm._v("Default Parameters "),
            _c("small", [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-light btn-sm",
                  on: { click: _vm.addParam },
                },
                [_vm._v("ADD")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.form.params, function (value, key) {
            return _c("div", { key: key, staticClass: "row" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.params[key],
                    expression: "form.params[key]",
                  },
                ],
                attrs: { type: "text", placeholder: "Default Value" },
                domProps: { value: _vm.form.params[key] },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form.params, key, $event.target.value)
                  },
                },
              }),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [
            _vm._v("Body "),
            _c("small", [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-light btn-sm",
                  on: { click: _vm.addBody },
                },
                [_vm._v("ADD")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.form.body, function (value, key) {
            return _c("div", { key: key, staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("input", {
                  staticClass: "form-control menu-control",
                  attrs: { type: "text", placeholder: "Key" },
                  domProps: { value: key },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.body[key],
                      expression: "form.body[key]",
                    },
                  ],
                  staticClass: "form-control menu-control",
                  attrs: { type: "text", placeholder: "Default Value" },
                  domProps: { value: _vm.form.body[key] },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form.body, key, $event.target.value)
                    },
                  },
                }),
              ]),
            ])
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [
            _vm._v("Response "),
            _c("small", [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-light btn-sm",
                  on: { click: _vm.addResponse },
                },
                [_vm._v("ADD")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm._l(_vm.form.response, function (value, key) {
            return _c("div", { key: key, staticClass: "row" }, [
              _c("div", { staticClass: "col-1" }, [
                _c("input", {
                  staticClass: "form-control menu-control",
                  attrs: { type: "text", placeholder: "Key" },
                  domProps: { value: key },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-11" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.response[key],
                      expression: "form.response[key]",
                    },
                  ],
                  staticClass: "form-control menu-control",
                  attrs: { type: "text", placeholder: "Default Value" },
                  domProps: { value: _vm.form.response[key] },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.form.response, key, $event.target.value)
                    },
                  },
                }),
              ]),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }