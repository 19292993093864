var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "action menu" }, [
    _c("form", { staticClass: "menu" }, [
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("URL")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.url,
                  expression: "form.url",
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.form.url },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "url", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6 menu" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("On Success")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.action.on_success,
                      expression: "action.on_success",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.action,
                        "on_success",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("Nothing"),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.$store.state.metaverse.manifest.actions,
                    function (action, key) {
                      return _c("option", { key: key }, [
                        _vm._v(
                          _vm._s(key) +
                            " (" +
                            _vm._s(action.action_type) +
                            ")\n\t\t\t\t\t\t\t"
                        ),
                      ])
                    }
                  ),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6 menu" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("On Error")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.action.on_error,
                      expression: "action.on_error",
                    },
                  ],
                  staticClass: "form-control",
                  on: {
                    change: function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.action,
                        "on_error",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                  },
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("Nothing"),
                  ]),
                  _vm._v(" "),
                  _vm._l(
                    _vm.$store.state.metaverse.manifest.actions,
                    function (action, key) {
                      return _c("option", { key: key }, [
                        _vm._v(
                          _vm._s(key) +
                            " (" +
                            _vm._s(action.action_type) +
                            ")\n\t\t\t\t\t\t\t"
                        ),
                      ])
                    }
                  ),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }