<template>
	<div class="menu list-menu">
		<div class="row mt-3">
			<div class="col">
				<h3 class="mb-2">Models</h3>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="add">Add Model</button>
			</div>
		</div>

		<div class="card card-body text-center mt-4 no-items" v-if="Object.keys(items).length === 0">
			<h2 class="my-4">No items</h2>
			<button class="btn btn-outline-primary btn-sm btn-sm-rel" @click="add">Add Model</button>
		</div>

		<div class="card card-body p-3 px-2 my-2" v-for="(value,key) in items" :key="key" @click="select(key)">
			<h2 class="mb-0 text-start">{{ tidyName(key) }}</h2>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"

import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";

export default Vue.extend({
	name: "ModelListMenu",
	data() {
		return {
			property: {
				property: 'model',
				object: null,
			},
		};
	},
	computed: {
		items: function () {
			return this.$store.state.metaverse.manifest.models
		}
	},
	methods: {
		...mapActions({
			manifestAdd: 'metaverse/manifestAdd',
		}),

		tidyName(name) {
			return name.substr(0, 1).toUpperCase() + name.substr(1).toLowerCase()
		},

		select(id) {
			this.$emit('select', {menu: 'models', item_id: id})
		},

		add() {
			const newModel = {
				id: uuidv4(),
				scene: "",
			}

			this.manifestAdd({
				...this.property,
				object: newModel,
			})

			this.$emit('add', newModel)
			this.select(newModel.id)
		}
	}
})
</script>

<style scoped>
.card {
	transition: all 0.2s ease-out;
}

h4 {
	font-size: 1em;
}

h6 {
	font-size: 0.6em;
	color: grey;
}

.card:hover {
	background: rgba(0, 0, 0, 0.9);
	border-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
}
</style>
