<template>
	<div class="menu list-menu">
		<div class="row mt-3">
			<div class="col">
				<h3 class="mb-2">Entities</h3>
			</div>
			<div class="col">
				<input v-model="search" placeholder="Search" class="form-control form-control-sm py-2 mt-0">
			</div>
			<div class="col-auto">
				<button class="btn btn-outline-primary btn-sm float-end" @click="add">Add</button>
			</div>
		</div>

		<div class="card card-body text-center mt-4 no-items" v-if="items.length === 0">
			<h2 class="my-4">No items</h2>
			<button class="btn btn-outline-primary btn-sm btn-sm-rel" @click="add">Add Entity</button>
		</div>

		<div class="card card-body p-1 my-2 text-start" v-for="(obj, key) in filteredItems" :key="`${obj.id}${key}`" @click="select(obj.id)">
			<h4>{{ obj.name ? obj.name : obj.id.substr(28) }} - {{obj.data.model.substr(0,obj.data.model.length - 37)}}</h4>
			<h6>{{ obj.id }}</h6>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"
import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";

export default Vue.extend({
	name: "EntityListMenu",
	data() {
		return {
			search: '',
			property: {
				property: 'entity',
				object: null,
			},
		};
	},
	watch: {
		search: function() {
			if (this.search.length > 3 || this.search === '')
				localStorage.setItem("editor:search:entities", this.search)
		}
	},
	computed: {
		items: function () {
			if (this.$store.state.metaverse.manifest.entities === null)
				return [];

			return this.$store.state.metaverse.manifest.entities
		},
		filteredItems: function() {
			if (this.search.length < 3)
				return this.items

			const sl = this.search.toLowerCase()
			return this.items.filter((i) => i.data.model.toLowerCase().indexOf(sl) > -1)
		}
	},
	methods: {
		...mapActions({
			manifestAdd: 'metaverse/manifestAdd',
		}),
		select(id) {
			this.$emit('select', {menu: 'entities', item_id: id})
		},
		add() {
			const newEntity = {
				id: uuidv4(),
				class: 'StaticObject',
				interactions: {
					click: {},
					proximity: {},
				},
				data: {
					model: "",
					position: [0, 0, 0],
					scale: [1, 1, 1],
					rotation: [0, 0, 0]
				},
			}

			this.manifestAdd({
				...this.property,
				object: newEntity,
			})

			this.select(newEntity.id)
		}
	},
	mounted() {
		this.search = localStorage.getItem("editor:search:entities") || ""
	}
})
</script>

<style scoped>
.card {
	transition: all 0.2s ease-out;
}

h4 {
	font-size: 1em;
}

h6 {
	font-size: 0.6em;
	color: grey;
}

.card:hover {
	background: rgba(0, 0, 0, 0.9);
	border-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
}
</style>
