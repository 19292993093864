<template>
	<div class="menu list-menu mt-4 mb-3">
		<div class="row">
			<div class="col">
				<h3 class="mb-2">Lights</h3>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="add">Add Light</button>
			</div>
		</div>

		<div class="card card-body text-center mt-4 no-items" v-if="items.length === 0">
			<h2 class="my-4">No items</h2>
			<button class="btn btn-outline-primary btn-sm btn-sm-rel" @click="add">Add Light</button>
		</div>

		<div class="card card-body p-3 my-2" v-for="(obj, key) in items" :key="obj.id" @click="select(obj.id)">
			<div class="row">
				<div class="col text-start">
					<h2>{{ obj.type }} #{{ key }}</h2>
					<h6>{{ obj.id }}</h6>
				</div>
				<div class="col-auto">
					<div class="cube" :style="{background: obj.color_sky || obj.color}"></div>
				</div>
				<div class="col-auto" v-if="obj.color_ground">
					<div class="cube" :style="{background: obj.color_ground}"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapActions} from "vuex"
import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";

export default Vue.extend({
	name: "LightListMenu",
	data() {
		return {
			property: {
				property: 'lighting',
				object: null,
			},
		}
	},
	computed: {
		items: function () {
			return this.$store.state.metaverse.manifest.lighting;
		}
	},
	methods: {
		...mapActions({
			manifestAdd: 'metaverse/manifestAdd',
		}),
		select(id) {
			this.$emit('select', {menu: 'lighting', item_id: id})
		},
		add() {
			const newLight = {
				id: uuidv4(),
				type: "ambient",
				color: "ffffff",
				intensity: 1
			}

			this.manifestAdd({
				...this.property,
				object: newLight,
			})

			this.select(newLight.id);
		}
	}
})
</script>

<style scoped>
.card {
	transition: all 0.2s ease-out;
}

h4 {
	font-size: 1em;
}

h6 {
	font-size: 0.6em;
	color: grey;
}

.cube {
	width: 25px;
	height: 25px;
}

.card:hover {
	background: rgba(0, 0, 0, 0.9);
	border-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
}
</style>
