<template>
	<div class="interaction-menu menu">
		<div
			class="interaction card p-3 m-0 mt-2"
			v-for="(i, key) in methods"
			:key="key"
		>
			<div @click="onToggle(i)" class="card-header row">
				<div class="col"><h6 class="text-uppercase mt-1">{{ toggles[i] ? '-' : '+' }} {{ i }} -> {{ interactions[i] ? interactions[i].action_type : 'None' }}</h6></div>
				<div class="col-auto text-end" v-if="interactions[i] && interactions[i].action_type">
					<button @click="removeInteraction(i)" class="btn btn-danger btn-xs">CLEAR</button>
				</div>
			</div>
			<ActionMenu
				v-show="toggles[i]"
				class="card-body p-0"
				:interaction-type="i"
				:interaction="interactions[i]"
				@update="update"
			/>
		</div>
	</div>
</template>

<script>
import ActionMenu from "@/components/editor/menus/ActionMenu.vue";

export default {
	name: "InteractionContainer",
	components: {
		ActionMenu,
	},
	props: {
		interactions: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			methods: ["click", "proximity"],
			toggles: {},
		};
	},
	methods: {
		update(data) {
			console.log('InteractionContainer::update', data)
			this.$emit('update', data)
		},
		onToggle(key) {
			this.$set(this.toggles, key,!this.toggles[key])
		},
		removeInteraction(key) {
			this.interactions[key] = {}
			this.$emit('update', this.interactions)
		},
	},
	beforeMount() {
		for (let t of this.methods) {
			// this.toggles[t] = false
			this.$set(this.toggles, t,false)
		}
	}
}
</script>

<style scoped>
.card-header {
	cursor: pointer;
}
</style>
