var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "menu environment-menu mt-4 mb-3" }, [
    _c("h3", { staticClass: "text-center" }, [_vm._v("Environment")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Spawn Point")]),
        _vm._v(" "),
        _c("Vector3", {
          on: { input: _vm.update },
          model: {
            value: _vm.form.spawn_point,
            callback: function ($$v) {
              _vm.$set(_vm.form, "spawn_point", $$v)
            },
            expression: "form.spawn_point",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Player Speed")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.form.player_speed,
            expression: "form.player_speed",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control",
        attrs: { type: "number", min: "1", step: "1" },
        domProps: { value: _vm.form.player_speed },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "player_speed", _vm._n($event.target.value))
            },
            _vm.update,
          ],
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("h6", [_vm._v("Shadows")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Shadows Enabled ")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.shadows,
            expression: "form.shadows",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.form.shadows)
            ? _vm._i(_vm.form.shadows, null) > -1
            : _vm.form.shadows,
        },
        on: {
          input: _vm.update,
          change: function ($event) {
            var $$a = _vm.form.shadows,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.form, "shadows", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.form,
                    "shadows",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.form, "shadows", $$c)
            }
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Shadow Quality ")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.shadow_quality,
              expression: "form.shadow_quality",
            },
          ],
          staticClass: "form-control",
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.form,
                  "shadow_quality",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              _vm.update,
            ],
          },
        },
        [
          _c("option", { attrs: { value: "low" } }, [_vm._v("Low")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "medium" } }, [_vm._v("Medium")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "high" } }, [_vm._v("High")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "very-high" } }, [
            _vm._v("Very High"),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("h6", [_vm._v("Sky")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Background")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.background,
            expression: "form.background",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "color" },
        domProps: { value: _vm.form.background },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "background", $event.target.value)
            },
            _vm.update,
          ],
        },
      }),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("h6", [_vm._v("Fog")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Color")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.fog.color,
            expression: "form.fog.color",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "color" },
        domProps: { value: _vm.form.fog.color },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form.fog, "color", $event.target.value)
            },
            _vm.update,
          ],
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Start")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.form.fog.start,
            expression: "form.fog.start",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control",
        attrs: { type: "number", min: "0", step: "1" },
        domProps: { value: _vm.form.fog.start },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form.fog, "start", _vm._n($event.target.value))
            },
            _vm.update,
          ],
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("End")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.form.fog.end,
            expression: "form.fog.end",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control",
        attrs: { type: "number", min: "0", step: "1" },
        domProps: { value: _vm.form.fog.end },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form.fog, "end", _vm._n($event.target.value))
            },
            _vm.update,
          ],
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }