var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "action" }, [
    _c("form", { staticClass: "menu" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Target")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.target,
              expression: "form.target",
            },
          ],
          staticClass: "form-control",
          domProps: { value: _vm.form.target },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "target", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Function Name")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.method,
              expression: "form.method",
            },
          ],
          staticClass: "form-control",
          domProps: { value: _vm.form.method },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "method", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Default Parameters")]),
          _vm._v(" "),
          _vm._l(_vm.form.params, function (value, key) {
            return _c("div", { key: key, staticClass: "row" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.params[key],
                    expression: "form.params[key]",
                  },
                ],
                attrs: { type: "text", placeholder: "Default Value" },
                domProps: { value: _vm.form.params[key] },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form.params, key, $event.target.value)
                  },
                },
              }),
            ])
          }),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  return _vm.form.params.push("")
                },
              },
            },
            [_vm._v("ADD")]
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Action")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { disabled: "", value: "external_call" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }