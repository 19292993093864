var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "menu settings-menu mt-3 mb-3" }, [
    _c("h3", { staticClass: "mb-2 text-center" }, [_vm._v("General Settings")]),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Metaverse Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.name,
                expression: "form.name",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.form.name },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "name", $event.target.value)
                },
                _vm.update,
              ],
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Description")]),
          _vm._v(" "),
          _c(
            "textarea",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.description,
                  expression: "form.description",
                },
              ],
              staticClass: "form-control",
              domProps: { value: _vm.form.description },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "description", $event.target.value)
                  },
                  _vm.update,
                ],
              },
            },
            [_vm._v(_vm._s(_vm.form.description))]
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    !_vm.simple
      ? _c("div", { staticClass: "row mt-3" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { staticClass: "mb-3" }, [_vm._v("Banner Image")]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.banner_src,
                        expression: "form.banner_src",
                      },
                    ],
                    staticClass: "btn btn-primary btn-xs float-end",
                    on: { click: _vm.removeBanner },
                  },
                  [_vm._v("Remove")]
                ),
                _vm._v(" "),
                _c("div", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.banner_src,
                      expression: "form.banner_src",
                    },
                  ],
                  staticClass: "img-container banner mt-3",
                  style: { backgroundImage: `url(${_vm.form.banner_src})` },
                }),
                _vm._v(" "),
                _c("ImageUploader", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.form.banner_src,
                      expression: "!form.banner_src",
                    },
                  ],
                  staticClass: "mb-3",
                  attrs: { endpoint: "/auth/users/files" },
                  on: { uploaded: _vm.onBannerUpload },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12 mt-3" }, [
            _c("div", { staticClass: "form-group" }, [
              _vm._m(0),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.form.logo_src,
                      expression: "form.logo_src",
                    },
                  ],
                  staticClass: "btn btn-primary btn-xs float-end",
                  on: { click: _vm.removeLogo },
                },
                [_vm._v("Remove")]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-3" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.form.logo_src,
                          expression: "form.logo_src",
                        },
                      ],
                      staticClass: "uploaded text-center",
                    },
                    [
                      _c("img", {
                        staticClass: "img-fluid logo",
                        attrs: { src: _vm.form.logo_src },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("ImageUploader", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.form.logo_src,
                        expression: "!form.logo_src",
                      },
                    ],
                    staticClass: "pb-3",
                    attrs: { endpoint: "/auth/users/files" },
                    on: { uploaded: _vm.onLogoUpload },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    false
      ? _c("section", [
          _c("hr"),
          _vm._v(" "),
          _c("h4", { staticClass: "mb-2 text-center" }, [
            _vm._v("Metaverse Configuration"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.nsfw,
                  expression: "form.nsfw",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.form.nsfw)
                  ? _vm._i(_vm.form.nsfw, null) > -1
                  : _vm.form.nsfw,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.form.nsfw,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && _vm.$set(_vm.form, "nsfw", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "nsfw",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "nsfw", $$c)
                  }
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.charge_entry,
                  expression: "form.charge_entry",
                },
              ],
              attrs: { type: "checkbox" },
              domProps: {
                checked: Array.isArray(_vm.form.charge_entry)
                  ? _vm._i(_vm.form.charge_entry, null) > -1
                  : _vm.form.charge_entry,
              },
              on: {
                change: function ($event) {
                  var $$a = _vm.form.charge_entry,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 &&
                        _vm.$set(_vm.form, "charge_entry", $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        _vm.$set(
                          _vm.form,
                          "charge_entry",
                          $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                        )
                    }
                  } else {
                    _vm.$set(_vm.form, "charge_entry", $$c)
                  }
                },
              },
            }),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.form.charge_entry
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Entry Amount")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.charge_entry_amount,
                expression: "form.charge_entry_amount",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "number" },
            domProps: { value: _vm.form.charge_entry_amount },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "charge_entry_amount", $event.target.value)
              },
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.form.charge_entry
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Entry Currency Contract")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.charge_entry_currency,
                expression: "form.charge_entry_currency",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.form.charge_entry_currency },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "charge_entry_currency", $event.target.value)
              },
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.form.charge_entry
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v("Destination Wallet")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.charge_entry_dest_wallet,
                expression: "form.charge_entry_dest_wallet",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.form.charge_entry_dest_wallet },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(
                  _vm.form,
                  "charge_entry_dest_wallet",
                  $event.target.value
                )
              },
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.simple
      ? _c("section", { attrs: { id: "moderation" } }, [
          _c("hr"),
          _vm._v(" "),
          _c("h4", { staticClass: "mb-2 text-center" }, [_vm._v("Moderation")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("div", { staticClass: "row" }, [
                _vm._m(3),
                _vm._v(" "),
                _c("small", { staticClass: "col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary btn-sm float-end",
                      on: { click: _vm.addAdmin },
                    },
                    [_vm._v("ADD")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.form.admins, function (value, key) {
                return _c("div", { key: key, staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.admins[key],
                          expression: "form.admins[key]",
                        },
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        placeholder: "Public Address (Solana)",
                      },
                      domProps: { value: _vm.form.admins[key] },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(_vm.form.admins, key, $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger btn-block mt-2",
                        on: {
                          click: function ($event) {
                            return _vm.removeAdmin(key)
                          },
                        },
                      },
                      [_vm._v("REMOVE")]
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("div", { staticClass: "row" }, [
                _vm._m(4),
                _vm._v(" "),
                _c("small", { staticClass: "col-6" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline-primary btn-sm float-end",
                      on: { click: _vm.addModerator },
                    },
                    [_vm._v("ADD")]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.form.moderators, function (value, key) {
                return _c("div", { key: key, staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-8" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.moderators[key],
                          expression: "form.moderators[key]",
                        },
                      ],
                      staticClass: "form-control menu-control",
                      attrs: {
                        type: "text",
                        placeholder: "Public Address (Solana)",
                      },
                      domProps: { value: _vm.form.moderators[key] },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.form.moderators,
                            key,
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger btn-block mt-2",
                        on: {
                          click: function ($event) {
                            return _vm.removeModerator(key)
                          },
                        },
                      },
                      [_vm._v("REMOVE")]
                    ),
                  ]),
                ])
              }),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.versions.length
      ? _c("div", [
          _c("hr"),
          _vm._v(" "),
          _c("h4", { staticClass: "mb-2 text-center" }, [_vm._v("Versions")]),
          _vm._v(" "),
          _c("div", { staticClass: "row mt-3" }, [
            _c("div", { staticClass: "col-6 offset-3" }, [
              _c("p", { staticClass: "info info-lg my-3 text-center" }, [
                _vm._v(
                  "Metaverse versions are stored forever on IPFS so you can always revert to a previous\n\t\t\t\t\tdate/time."
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "info info-lg my-3 text-center" }, [
                _vm._v(
                  "Loading a previous version will create a new draft with the state of the old metaverse, from\n\t\t\t\t\tthere you can modify and save the draft to update your metaverse."
                ),
              ]),
              _vm._v(" "),
              _c(
                "select",
                {
                  staticClass: "form-control-sm text-left",
                  on: { change: _vm.onVersionSelect },
                },
                [
                  _c("option", { attrs: { disabled: "", selected: "" } }, [
                    _vm._v("Select previous version"),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.versions, function (version, key) {
                    return _c(
                      "option",
                      { key: version.uuid, domProps: { value: key } },
                      [
                        _vm._v(
                          "\n\t\t\t\t\t\t" +
                            _vm._s(
                              new Date(version.created_at).toLocaleString()
                            ) +
                            " -\n\t\t\t\t\t\t" +
                            _vm._s(version.uuid) +
                            "\n\t\t\t\t\t"
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("div", { staticClass: "row mt-3 text-left" }, [
                _c("div", { staticClass: "col" }, [
                  _c("p", { staticClass: "small" }, [
                    _vm._v("Version: "),
                    _c("code", { staticClass: "mx-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.selectedVersion.uuid
                            ? _vm.selectedVersion.uuid
                            : "No previous version selected"
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "small" }, [
                    _vm._v("Date Saved:\n\t\t\t\t\t\t\t"),
                    _c("code", { staticClass: "mx-3" }, [
                      _vm._v(
                        _vm._s(
                          _vm.selectedVersion.created_at
                            ? new Date(
                                _vm.selectedVersion.created_at
                              ).toLocaleString()
                            : "-"
                        )
                      ),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    !_vm.$store.state.wallet.connected
                      ? _c("div", [_c("p", [_vm._v("Connect wallet")])])
                      : _c(
                          "PhantomSign",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { disabled: !_vm.selectedVersion.uuid },
                            on: { sign: _vm.onSaveSigned },
                          },
                          [_vm._v("Load Previous Version")]
                        ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-inline-block" }, [
      _c("label", {}, [_vm._v("Logo")]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0 p-0 info" }, [
        _vm._v("Displayed on the world map"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-inline-block" }, [
      _c("label", [_vm._v("Not Safe For Work (NSFW)")]),
      _vm._v(" "),
      _c("p", { staticClass: "info" }, [
        _vm._v(
          "If enabled, users will be able to create NSFW content but will not be listed to users without\n\t\t\t\t\tKYC verification."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "d-inline-block" }, [
      _c("label", [_vm._v("Charge Entry")]),
      _vm._v(" "),
      _c("p", { staticClass: "info" }, [
        _vm._v(
          "If enabled, users will be charged when joining the room, granting them access for the duration\n\t\t\t\t\tof their session."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-6" }, [
      _c("label", [_vm._v("Admins")]),
      _vm._v(" "),
      _c("p", { staticClass: "info" }, [
        _vm._v(
          "Admins are able to change all settings within the metaverse with no access to the underlying\n\t\t\t\t\t\tNFT."
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "info warning" }, [
        _c("strong", [_vm._v("NOTE:")]),
        _vm._v(" Only ever grant admin access to trusted users"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col-6" }, [
      _c("label", [_vm._v("Moderators")]),
      _vm._v(" "),
      _c("p", { staticClass: "info" }, [
        _vm._v(
          "Moderators are able to manage certain aspects of the metaverse such as chat, players &\n\t\t\t\t\t\tinteractive items."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }