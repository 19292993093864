<template>
	<div class="action">

		<form class="form menu">
			
            <div class="form-group mb-3">
                <label>New Spot</label>
                <Vector3 v-model="form.position"></Vector3>
            </div>

			<div class="row mt-2">
				<div class="col-12">
					<button class="btn btn-outline-primary btn-block" @click="save">Update</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

import Vector3 from "../fields/Vector3";

export default Vue.extend({
	name: "TeleportTo",
    components: {
        Vector3,
    },
	mixins: [ actionsMixin ],
	data() {
		return {
			form: {
				position: [0,0,0]
			}
		}
	},
	methods: {
		addParam: function(e) {
			e.preventDefault()
			console.log(this.form)
			this.form.params.push("");
		},

		addHeader: function(e) {
			e.preventDefault()
			console.log(this.form)

			this.$set(this.form.headers, "NEW", "")
			// this.form.headers['NEW'] = "";
		},

		addBody: function(e) {
			e.preventDefault()
			console.log(this.form)
			this.$set(this.form.body, "NEW", "")
			// this.form.body['NEW'] = "";
		},

		addResponse: function(e) {
			e.preventDefault()
			console.log(this.form)
			this.form.response.push("")
			// this.form.body['NEW'] = "";
		}
	},
})
</script>

<style scoped>

</style>
