<template>
	<div>
		<h5>Teleport to Metaverse</h5>
		<div class="form-group">
			<label>Target Metaverse</label>
			<select v-model="form.metaverse_addr" class="form-control">
				<option v-for="(m,k) in metaverse" :value="m.uuid">{{m.name}}</option>
			</select>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import actionsMixin from '@/components/editor/actions/actionsMixin'
import User from '@/js/api/user'

export default Vue.extend({
	name: 'TeleportToMetaverse',
	mixins: [actionsMixin],
	data() {
		return {
			metaverse: [],
			form: {
				metaverse_addr: null,
			},
		}
	},
	methods: {
		getMetaverseInstances() {
			User.indexMetaverse(this.$store.state.wallet.address).then(response => {
				this.metaverse = response.data;
			});
		},
		// update() {
		// 	this.save()
		// }
	},
	mounted() {
		this.getMetaverseInstances()
	}
})
</script>

<style lang="scss" scoped>
.form-control {
	font-size: 0.8rem;
	background: unset;
	color: white;
	text-align: left;
}

option {
	color: black !important;
}
</style>
