<template>
	<div class="action">

		<form class="form menu">
			<div class="form-group">
				<label>Method</label>
				<select class="form-control" v-model="form.method">
					<option value="GET">GET</option>
					<option value="POST">POST</option>
					<option value="PUT">PUT</option>
					<option value="DELETE">DELETE</option>
				</select>
			</div>

			<div class="form-group">
				<label>URL</label>
				<input class="form-control" type="text" v-model="form.url" placeholder="URL"/>
			</div>

			<div class="form-group">
				<label>Headers <small><button class="btn btn-outline-light btn-sm" @click="addHeader">ADD</button></small></label>
				<div class="row" v-for="(value,key) in form.headers" :key="key">
					<div class="col-6">
						<input class="form-control menu-control" type="text" :value="key" placeholder="Header"/>
					</div>
					<div class="col-6">
						<input class="form-control menu-control" type="text" v-model="form.headers[key]" placeholder="Value"/>
					</div>
				</div>
			</div>
			<hr>
			<div class="form-group">
				<label>Default Parameters <small><button class="btn btn-outline-light btn-sm" v-on:click="addParam">ADD</button></small></label>

				<div class="row" v-for="(value,key) in form.params" :key="key">
					<input type="text" v-model="form.params[key]" placeholder="Default Value"/>
				</div>
				<!--				TODO Key -> Value -->
			</div>

			<hr>
			<div class="form-group">
				<label>Body <small><button class="btn btn-outline-light btn-sm" v-on:click="addBody">ADD</button></small></label>

				<div class="row" v-for="(value,key) in form.body" :key="key">
					<div class="col-6">
						<input class="form-control menu-control" type="text" :value="key" placeholder="Key"/>
					</div>
					<div class="col-6">
						<input class="form-control menu-control" type="text" v-model="form.body[key]" placeholder="Default Value"/>
					</div>
				</div>
				<!--				TODO Key -> Value -->
			</div>
			<hr>

			<div class="form-group">
				<label>Response <small><button class="btn btn-outline-light btn-sm" v-on:click="addResponse">ADD</button></small></label>

				<div class="row" v-for="(value,key) in form.response" :key="key">
					<div class="col-1">
						<input class="form-control menu-control" type="text" :value="key" placeholder="Key"/>
					</div>
					<div class="col-11">
						<input class="form-control menu-control" type="text" v-model="form.response[key]" placeholder="Default Value"/>
					</div>
				</div>
				<!--				TODO Key -> Value -->
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
	name: "ApiCall",
	mixins: [ actionsMixin ],
	data() {
		return {
			form: {
				params: [],
				method: "GET",
				url: "",
				headers: {},
				body: {}
			}
		}
	},
	methods: {
		addParam: function(e) {
			e.preventDefault()
			console.log(this.form)
			this.form.params.push("");
		},

		addHeader: function(e) {
			e.preventDefault()
			console.log(this.form)

			this.$set(this.form.headers, "NEW", "")
			// this.form.headers['NEW'] = "";
		},

		addBody: function(e) {
			e.preventDefault()
			console.log(this.form)
			this.$set(this.form.body, "NEW", "")
			// this.form.body['NEW'] = "";
		},

		addResponse: function(e) {
			e.preventDefault()
			console.log(this.form)
			this.form.response.push("")
			// this.form.body['NEW'] = "";
		}
	},
})
</script>

<style scoped>

</style>
