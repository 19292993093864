<template>
	<div class="action">
		<form class="menu">
			<div class="form-group">
				<label>Action</label>
				<input disabled value="external_call" class="form-control">
			</div>

			<div class="form-group">
				<label>Target</label>
				<input v-model="form.target" class="form-control">
			</div>

			<div class="form-group">
				<label>Function Name</label>
				<input v-model="form.method" class="form-control">
			</div>

			<div class="form-group">
				<label>Default Parameters</label>


				<div class="row" v-for="(value,key) in form.params" :key="key">
					<input type="text" v-model="form.params[key]" placeholder="Default Value"/>
				</div>
				<!--				TODO Key -> Value -->

				<button v-on:click="form.params.push('')">ADD</button>
			</div>
		</form>
	</div>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
	name: "ExternalCall",
	mixins: [ actionsMixin ],
	data() {
		return {
			form: {
				target: "Window",
				method: "",
				params: []
			}
		}
	},
})
</script>

<style scoped>

</style>
