var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "action" }, [
    _c("form", { staticClass: "form menu" }, [
      _c(
        "div",
        { staticClass: "form-group mb-3" },
        [
          _c("label", [_vm._v("New Spot")]),
          _vm._v(" "),
          _c("Vector3", {
            model: {
              value: _vm.form.position,
              callback: function ($$v) {
                _vm.$set(_vm.form, "position", $$v)
              },
              expression: "form.position",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-2" }, [
        _c("div", { staticClass: "col-12" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-block",
              on: { click: _vm.save },
            },
            [_vm._v("Update")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }