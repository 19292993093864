<template>
	<form class="menu">
		<div class="form-group">
			<label>Object</label>
			<input v-model="form.target_id" class="form-control"/>
		</div>
		<div class="form-group">
			<label>Animation</label>
			<input v-model="form.animation_name" class="form-control"/>
		</div>
		<div class="form-group">
			<label>Block Movement</label>
			<input type="checkbox" v-model="form.blocking"/>
		</div>
	</form>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
	name: 'AnimateObject',
	mixins: [actionsMixin],
	data() {
		return {
			form: {
				target_id: null,
				animation_name: 'default',
				blocking: false
			},
		}
	},
})
</script>

<style lang="scss" scoped>
.form-control {
	font-size: 0.8rem;
	background: unset;
	color: white;
	text-align: left;
}
</style>
