var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "action menu" }, [
    _c("form", { staticClass: "menu" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Title")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.title,
              expression: "form.title",
            },
          ],
          staticClass: "form-control",
          domProps: { value: _vm.form.title },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "title", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Message")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.message,
              expression: "form.message",
            },
          ],
          staticClass: "form-control",
          domProps: { value: _vm.form.message },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "message", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Background Color")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.backgroundColor,
              expression: "form.backgroundColor",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "color" },
          domProps: { value: _vm.form.backgroundColor },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "backgroundColor", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Text Color")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.textColor,
              expression: "form.textColor",
            },
          ],
          staticClass: "form-control",
          attrs: { type: "color" },
          domProps: { value: _vm.form.textColor },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "textColor", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "form-group" },
        [
          _c("label", [_vm._v("Center Offset")]),
          _vm._v(" "),
          _c("Vector3", {
            model: {
              value: _vm.form.center_offset,
              callback: function ($$v) {
                _vm.$set(_vm.form, "center_offset", $$v)
              },
              expression: "form.center_offset",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }