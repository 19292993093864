var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "light ambient-light menu" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Sky Color")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.color_sky,
            expression: "form.color_sky",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "color" },
        domProps: { value: _vm.form.color_sky },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "color_sky", $event.target.value)
            },
            function ($event) {
              return _vm.$emit("update", _vm.form)
            },
          ],
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Ground Color")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.color_ground,
            expression: "form.color_ground",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "color" },
        domProps: { value: _vm.form.color_ground },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "color_ground", $event.target.value)
            },
            function ($event) {
              return _vm.$emit("update", _vm.form)
            },
          ],
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Intensity")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.intensity,
                expression: "form.intensity",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control menu-control",
            attrs: { type: "number", min: "0", max: "1", step: "0.1" },
            domProps: { value: _vm.form.intensity },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "intensity", _vm._n($event.target.value))
                },
                function ($event) {
                  return _vm.$emit("update", _vm.form)
                },
              ],
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-8" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.intensity,
                expression: "form.intensity",
                modifiers: { number: true },
              },
            ],
            staticClass: "btn-block mt-2",
            attrs: { type: "range", min: "0.0", max: "1.0", step: "0.01" },
            domProps: { value: _vm.form.intensity },
            on: {
              input: function ($event) {
                return _vm.$emit("update", _vm.form)
              },
              __r: function ($event) {
                _vm.$set(_vm.form, "intensity", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }