<template>
	<div class="action">
		<form class="menu">
			<div class="form-group">
				<label>Chain</label>
				<!--				TODO Verify chain ID's-->
				<select :value="form.chain_id" class="form-control">
					<option value="0">Solana</option>
					<option disabled value="1">Ethereum (Coming Soon!)</option>
					<option disabled value="2">BSC (Coming Soon!)</option>
					<option disabled value="3">Matic (Coming Soon!)</option>
				</select>
			</div>

			<div class="form-group">
				<label>Candy Machine Address</label>
				<input v-model="form.candy_machine_id" class="form-control" placeholder="Candy Machine Address">
			</div>
		</form>
	</div>
</template>

<script>
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default {
	name: "MintNFT",
	mixins: [actionsMixin],
	data() {
		return {
			form: {
				candy_machine_id: "",
				mint_addr: "",
				chain_id: 0,
			}
		}
	},
	computed: {
		exampleSaleLink() {
			let uri;

			switch (this.form.sale_platform) {
				case 0: //Magic Eden
				default:
					uri = "https://hyperspace.xyz/token/CyHQgZR6hLJJJzQXT5yqFji9Rj7xJN6JTdFf4dDvRT6r";
					break;

				case 1: //Opensea
					uri = "https://opensea.io/assets/0x60e4d786628fea6478f785a6d7e704777c86a7c6/2461";
					break;
			}

			return uri
		},
	},
}
</script>

<style scoped>

</style>
