<template>
	<form class="menu">
		<div class="form-group">
			<label>Object</label>
			<input v-model="form.target_id" class="form-control"/>
		</div>
	</form>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
	name: 'HideObject',
	mixins: [actionsMixin],
	data() {
		return {
			form: {
				target_id: null
			},
		}
	},
})
</script>

<style lang="scss" scoped>
.form-control {
	font-size: 0.8rem;
	background: unset;
	color: white;
	text-align: left;
}
</style>
