var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form", { staticClass: "menu" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Object")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.target_id,
            expression: "form.target_id",
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.form.target_id },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "target_id", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Animation")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.animation_name,
            expression: "form.animation_name",
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.form.animation_name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "animation_name", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Block Movement")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.blocking,
            expression: "form.blocking",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.form.blocking)
            ? _vm._i(_vm.form.blocking, null) > -1
            : _vm.form.blocking,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.form.blocking,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.form, "blocking", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.form,
                    "blocking",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.form, "blocking", $$c)
            }
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }