<template>
	<div class="menu terrain-menu mt-4 mb-3">
		<div class="row">
			<div class="col">
				<h3 class="mb-2">Sound</h3>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="onBack">Back</button>
			</div>
		</div>

		<div class="form-group">
			<label>ID</label>
			<input :value="form.id" disabled class="form-control">
		</div>

		<div class="form-group">
			<label>Name</label>
			<input :value="form.name" class="form-control">
		</div>

		<!--		<div class="form-group">-->
		<!--			<label>Url</label>-->
		<!--			<input disabled v-model="form.url" class="form-control">-->
		<!--		</div>-->

		<div class="form-group">
			<label>Volume</label>

			<div class="row">
				<div class="col-2">
					<input readonly v-model="form.volume" class="form-control form-control-sm">
				</div>
				<div class="col">
					<input type="range" v-model.number="form.volume" class="form-control" min="0" max="100">
				</div>
			</div>

		</div>

		<hr>

		<h5 class="text-left">Select Track</h5>
		<div class="col-12">
			<UserNFTCollection :active="form.name" @add="musicSelect" filter="audio/mp3"></UserNFTCollection>
		</div>


		<!--		<div class="form-group">-->
		<!--			<label>Top Left</label>-->
		<!--			<Vector3 :vector="form.area.tl" @update="(v) => form.area.tl = v"></Vector3>-->
		<!--		</div>-->

		<!--		<div class="form-group">-->
		<!--			<label>Bottom Right</label>-->
		<!--			<Vector3 :vector="form.area.br" @update="(v) => form.position = v"></Vector3>-->
		<!--		</div>-->

		<hr>

		<div class="text-center">
			<button class="btn btn-primary mt-3 btn-wide" @click="update">Update</button>
			<button v-on:click="$emit('delete', form.id)" class="btn btn-danger mt-3 btn-wide">Delete</button>
		</div>
	</div>
</template>

<script>
import Vector3 from "../fields/Vector3";
import "@google/model-viewer"
import UserNFTCollection from "../../nft/UserNFTCollection";

export default {
	name: "SoundMenu",
	components: {UserNFTCollection, Vector3},
	props: {
		meta: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			form: {
				url: "",
				token_addr: "",
				name: "",
				volume: 100,
			}
		}
	},
	computed: {
		item() {
			return this.$store.state.metaverse.manifest.sound.area_music.filter(sound => {
				return sound.id === this.meta
			})[0]
		}
	},
	methods: {
		onBack() {
			console.log("OnBack called", this.form)
			if (this.form.isNew) { //If is new we just delete as its not configured
				this.$emit('delete', this.form.id)
				return
			}

			this.$emit('update', 'back')
		},
		update(e) {
			e.preventDefault()
			this.$emit("update", this.form);
		},
		musicSelect(nft) {
			const uri = this.getMusicUri(nft)

			if (uri === null) {
				console.log("no uri", nft)
				return
			}

			this.$set(this.form, "url", uri)
			this.$set(this.form, "name", nft.name)

			// this.form.name = nft.name
			// this.form.url = uri
		},

		getMusicUri(nft) {
			for (let i = 0; i < nft.files.length; i++) {
				if (nft.files[i].type === "audio/mp3") {
					return nft.files[i].uri
				}
			}
			return null
		}

	},
	beforeMount() {
		this.form = Object.assign({}, this.item);
		console.log("mounted", this.form)
	}
}
</script>

<style scoped>
.form-control {
	background: transparent;
	color: white;
}
</style>
