var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "light ambient-light menu" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Color")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.color,
            expression: "form.color",
          },
        ],
        staticClass: "form-control",
        attrs: { type: "color" },
        domProps: { value: _vm.form.color },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "color", $event.target.value)
            },
            function ($event) {
              return _vm.$emit("update", _vm.form)
            },
          ],
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Intensity")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.intensity,
                expression: "form.intensity",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control menu-control",
            attrs: { type: "number", min: "0", max: "1", step: "0.1" },
            domProps: { value: _vm.form.intensity },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form, "intensity", _vm._n($event.target.value))
                },
                function ($event) {
                  return _vm.$emit("update", _vm.form)
                },
              ],
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-8" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.intensity,
                expression: "form.intensity",
                modifiers: { number: true },
              },
            ],
            staticClass: "btn-block mt-2",
            attrs: { type: "range", min: "0.0", max: "1.0", step: "0.01" },
            domProps: { value: _vm.form.intensity },
            on: {
              input: function ($event) {
                return _vm.$emit("update", _vm.form)
              },
              __r: function ($event) {
                _vm.$set(_vm.form, "intensity", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Width")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.form.width,
            expression: "form.width",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control",
        attrs: { type: "number", min: "0.0", step: "0.1" },
        domProps: { value: _vm.form.width },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "width", _vm._n($event.target.value))
            },
            function ($event) {
              return _vm.$emit("update", _vm.form)
            },
          ],
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Height")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.form.height,
            expression: "form.height",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control",
        attrs: { type: "number", min: "0.0", step: "0.1" },
        domProps: { value: _vm.form.height },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "height", _vm._n($event.target.value))
            },
            function ($event) {
              return _vm.$emit("update", _vm.form)
            },
          ],
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Position")]),
        _vm._v(" "),
        _c("Vector3", {
          on: {
            input: function ($event) {
              return _vm.$emit("update", _vm.form)
            },
          },
          model: {
            value: _vm.form.position,
            callback: function ($$v) {
              _vm.$set(_vm.form, "position", $$v)
            },
            expression: "form.position",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Rotation")]),
        _vm._v(" "),
        _c("Vector3", {
          on: {
            input: function ($event) {
              return _vm.$emit("update", _vm.form)
            },
          },
          model: {
            value: _vm.form.rotation,
            callback: function ($$v) {
              _vm.$set(_vm.form, "rotation", $$v)
            },
            expression: "form.rotation",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }