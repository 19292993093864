var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "menu class-data" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Model")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.data.model,
              expression: "form.data.model",
            },
          ],
          staticClass: "form-control",
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.$set(
                  _vm.form.data,
                  "model",
                  $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                )
              },
              _vm.update,
            ],
          },
        },
        _vm._l(
          _vm.$store.state.metaverse.manifest.models,
          function (model, key) {
            return _c(
              "option",
              {
                key: key,
                domProps: { value: key, selected: _vm.form.data.model === key },
              },
              [_vm._v(_vm._s(key) + "\n\t\t\t")]
            )
          }
        ),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Position")]),
        _vm._v(" "),
        _c("Vector3", {
          on: { input: _vm.update },
          model: {
            value: _vm.form.data.position,
            callback: function ($$v) {
              _vm.$set(_vm.form.data, "position", $$v)
            },
            expression: "form.data.position",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Rotation")]),
        _vm._v(" "),
        _c("Vector3", {
          on: { input: _vm.update },
          model: {
            value: _vm.form.data.rotation,
            callback: function ($$v) {
              _vm.$set(_vm.form.data, "rotation", $$v)
            },
            expression: "form.data.rotation",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Scale")]),
        _vm._v(" "),
        _c("Vector3", {
          on: { input: _vm.update },
          model: {
            value: _vm.form.data.scale,
            callback: function ($$v) {
              _vm.$set(_vm.form.data, "scale", $$v)
            },
            expression: "form.data.scale",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { attrs: { id: "click" } }, [
        _c(
          "div",
          { staticClass: "interaction click card p-2 m-3" },
          [
            _c(
              "div",
              {
                staticClass: "card-header row",
                on: {
                  click: function ($event) {
                    _vm.toggleClick = !_vm.toggleClick
                  },
                },
              },
              [
                _c("div", { staticClass: "col" }, [
                  _c("h6", { staticClass: "text-uppercase mt-1" }, [
                    _vm._v(
                      _vm._s(_vm.toggleClick ? "-" : "+") +
                        " CLICK -> " +
                        _vm._s(_vm.interactions["click"].action_type || "None")
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.interactions.click.action_type
                  ? _c("div", { staticClass: "col-auto text-end" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger btn-xs",
                          on: { click: _vm.removeClickInteraction },
                        },
                        [_vm._v("CLEAR")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c("ActionMenu", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.toggleClick,
                  expression: "toggleClick",
                },
              ],
              staticClass: "card-body p-0",
              attrs: {
                "interaction-type": "click",
                "alpha-id": _vm.alphaId,
                interaction: _vm.interactions["click"],
              },
              on: { update: _vm.updateInteractions },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { id: "proximity" } }, [
        _c("div", { staticClass: "interaction proximity card p-2 m-3" }, [
          _c(
            "div",
            {
              staticClass: "card-header row",
              on: {
                click: function ($event) {
                  _vm.toggleProximity = !_vm.toggleProximity
                },
              },
            },
            [
              _c("div", { staticClass: "col" }, [
                _c("h6", { staticClass: "text-uppercase mt-1" }, [
                  _vm._v(
                    _vm._s(_vm.toggleProximity ? "-" : "+") +
                      " PROXIMITY -> " +
                      _vm._s(
                        _vm.interactions["proximity"].action_type || "None"
                      )
                  ),
                ]),
              ]),
              _vm._v(" "),
              _vm.interactions.proximity.action_type
                ? _c("div", { staticClass: "col-auto text-end" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger btn-xs",
                        on: { click: _vm.removeProximityInteraction },
                      },
                      [_vm._v("CLEAR")]
                    ),
                  ])
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.toggleProximity,
                  expression: "toggleProximity",
                },
              ],
              staticClass: "card-body p-0",
            },
            [
              _c("ActionMenu", {
                staticClass: "p-0",
                attrs: {
                  "interaction-type": "proximity",
                  "alpha-id": _vm.alphaId,
                  interaction: _vm.interactions["proximity"],
                },
                on: { update: _vm.updateInteractions },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("label", [_vm._v("Interactions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }