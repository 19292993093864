var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "menu terrain-list-menu mt-4 mb-3" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("h5", { staticClass: "text-left mt-2" }, [_vm._v("General Music")]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-2 mb-3" }, [
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            {
              staticClass: "card card-body sound-option",
              on: {
                click: function ($event) {
                  return _vm.update("ambient_music")
                },
              },
            },
            [
              _c("h5", { staticClass: "mb-4" }, [_vm._v("Ambient Music")]),
              _vm._v(" "),
              _c("i", { staticClass: "fa fa-music fa-3x" }),
              _vm._v(" "),
              _c("i", { staticClass: "mt-2" }, [
                _vm._v(
                  _vm._s(
                    _vm.$store.state.metaverse.manifest.sound["ambient_music"]
                      .name
                  )
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-6" }, [
          _c(
            "div",
            {
              staticClass: "card card-body sound-option",
              on: {
                click: function ($event) {
                  return _vm.update("movement")
                },
              },
            },
            [
              _c("h5", { staticClass: "mb-4" }, [_vm._v("Movement Sound")]),
              _vm._v(" "),
              _c("i", { staticClass: "fa fa-music fa-3x" }),
              _vm._v(" "),
              _c("i", { staticClass: "mt-2" }, [
                _vm._v(
                  _vm._s(
                    _vm.$store.state.metaverse.manifest.sound["movement"].name
                  )
                ),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-sm float-end mx-2",
              attrs: { disabled: "" },
              on: { click: _vm.add },
            },
            [_vm._v("Add Area Music")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.items.length === 0
        ? _c("div", { staticClass: "row" }, [_vm._m(2)])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.items, function (sound, key) {
        return _c(
          "div",
          {
            key: sound.id,
            staticClass: "terrain card card-body p-1 my-2",
            on: {
              click: function ($event) {
                return _vm.update(sound.id)
              },
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-4" }, [
                _c("h4", [_vm._v(_vm._s(sound.name))]),
                _vm._v(" "),
                _c("h6", [_vm._v(_vm._s(sound.id))]),
              ]),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c("h3", { staticClass: "mb-2" }, [_vm._v("Sound")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("h5", { staticClass: "text-left mt-3" }, [_vm._v("Area Music")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "card card-body mt-3" }, [
        _c("i", [_vm._v("No area music setup")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }