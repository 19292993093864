<template>
	<div class="menu class-data">
		<div class="form-group">
			<label>Model</label>
			<select @change="update" class="form-control" v-model="form.data.model">
				<option v-for="(model,key) in $store.state.metaverse.manifest.models" :value="key" :selected="form.data.model === key" :key="key">{{
						key
					}}
				</option>
			</select>
		</div>

		<div class="form-group">
			<label>Position</label>
			<Vector3 v-model="form.data.position" @input="update"/>
		</div>

		<div class="form-group">
			<label>Rotation</label>
			<Vector3 v-model="form.data.rotation" @input="update"/>
		</div>

		<div class="form-group">
			<label>Scale</label>
			<Vector3 v-model="form.data.scale" @input="update"/>
		</div>

		<hr>
		<div class="form-group">
			<div class="row">
				<div class="col">
					<label>Interactions</label>
				</div>
			</div>

			<div id="click">
				<div class="interaction click card p-2 m-3">
					<div @click="toggleClick = !toggleClick" class="card-header row">
						<div class="col"><h6 class="text-uppercase mt-1">{{ toggleClick ? '-' : '+' }} CLICK -> {{ interactions['click'].action_type || 'None' }}</h6></div>
						<div class="col-auto text-end" v-if="interactions.click.action_type">
							<button @click="removeClickInteraction" class="btn btn-danger btn-xs">CLEAR</button>
						</div>
					</div>
					<ActionMenu
							v-show="toggleClick"
							class="card-body p-0"
							interaction-type="click"
							:alpha-id="alphaId"
							:interaction="interactions['click']"
							@update="updateInteractions"
					/>
				</div>
			</div>

			<div id="proximity">
				<div class="interaction proximity card p-2 m-3">
					<div @click="toggleProximity = !toggleProximity" class="card-header row">
						<div class="col"><h6 class="text-uppercase mt-1">{{ toggleProximity ? '-' : '+' }} PROXIMITY -> {{ interactions['proximity'].action_type || 'None' }}</h6></div>
						<div class="col-auto text-end" v-if="interactions.proximity.action_type">
							<button @click="removeProximityInteraction" class="btn btn-danger btn-xs">CLEAR</button>
						</div>
					</div>

					<div class="card-body p-0" v-show="toggleProximity">
						<ActionMenu
								class="p-0"
								interaction-type="proximity"
								:alpha-id="alphaId"
								:interaction="interactions['proximity']"
								@update="updateInteractions"
						/>
					</div>

				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vector3 from "@/components/editor/fields/Vector3"
import ActionMenu from "../menus/ActionMenu.vue"

export default {
	name: "StaticObject",
	components: {
		Vector3,
		ActionMenu,
	},
	props: {
		alphaId: {type: String},
		data: {
			type: Object,
			required: true
		},
		interactions: {
			type: Object,
			required: true,
		}
	},
	data() {
		return {
			form: {
				data: this.data,
				interactions: this.interactions,
			},
			toggleClick: this.interactions.click.action_type,
			toggleProximity: this.interactions.proximity.action_type,
		};
	},
	watch: {
		data: {
			handler(newValue) {
				this.form.data = newValue
			},
			deep: true,
		},
		interactions: {
			handler(newValue) {
				this.form.interactions = newValue
			},
			deep: true,
		},
	},
	methods: {
		update() {
			console.log('StaticObject::form', this.form)
			this.$emit("updateEntity", this.form);
		},
		updateInteractions(interaction) {
			console.log("Updating Interactions", interaction)
			this.form.interactions = {
				...this.form.interactions,
				...interaction,
			}
			this.$emit("updateInteractions", this.form.interactions);
		},
		removeClickInteraction() {
			this.form.interactions.click = {}
			this.$emit("updateInteractions", this.form.interactions);
		},
		removeProximityInteraction() {
			this.form.interactions.proximity = {}
			this.$emit("updateInteractions", this.form.interactions);
		}
	},
	// beforeMount() {
	// 	this.form = {
	// 		data: this.data,
	// 		interactions: this.interactions,
	// 	}

	// 	console.log("form", this.form);
	// }
}
</script>

<style scoped>
.card-header {
	cursor: pointer;
}
</style>
