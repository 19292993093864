var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "action" }, [
    _c("form", { staticClass: "menu" }, [
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Chain")]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-control",
            domProps: { value: _vm.form.chain_id },
          },
          [
            _c("option", { attrs: { value: "0" } }, [_vm._v("Solana")]),
            _vm._v(" "),
            _c("option", { attrs: { disabled: "", value: "1" } }, [
              _vm._v("Ethereum (Coming Soon!)"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { disabled: "", value: "2" } }, [
              _vm._v("BSC (Coming Soon!)"),
            ]),
            _vm._v(" "),
            _c("option", { attrs: { disabled: "", value: "3" } }, [
              _vm._v("Matic (Coming Soon!)"),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Candy Machine Address")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.candy_machine_id,
              expression: "form.candy_machine_id",
            },
          ],
          staticClass: "form-control",
          attrs: { placeholder: "Candy Machine Address" },
          domProps: { value: _vm.form.candy_machine_id },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "candy_machine_id", $event.target.value)
            },
          },
        }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }