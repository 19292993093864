<template>
	<div class="menu terrain-list-menu mt-4 mb-3">
		<div class="row">
			<div class="col-md">
				<h3 class="mb-2">Terrain</h3>
			</div>
<!--			<div class="col-md"><input class="form-control form-control-sm text-center search" placeholder="Search"-->
<!--															v-model="search"></div>-->
			<div class="col-md">
				<button class="btn btn-outline-primary btn-sm btn-sm-rel" @click="add">Add Terrain</button>
			</div>
			<div class="col-md">
				<button :class="`btn btn-outline-${eventsOnly ? 'success' : 'secondary'} btn-sm btn-sm-rel`"
								@click="toggleEvents">Events Only
				</button>
			</div>
		</div>

			<div class="card card-body text-center mt-4 no-items" v-if="items.length === 0">
				<h2 class="my-4">No items</h2>
				<button class="btn btn-outline-primary btn-sm btn-sm-rel" @click="add">Add Terrain</button>
			</div>

		<div class="terrain card card-body p-1 my-2" v-for="terrain in items" :key="terrain.id"
				 @click="select(terrain.id)">
			<div class="row">
				<div class="col text-start">
					<h2>{{ terrain.name.substr(0,20) }}{{terrain.name.length > 20 ? '...' : ''}}</h2>
					<h6>{{ terrain.id }}</h6>
				</div>
				<div class="col-auto text-center">
					<h4>Events</h4>
					<h6>{{ terrain.interactions ? Object.keys(terrain.interactions).length : 0 }}</h6>
				</div>
				<div class="col-auto text-center">
					<h4>Size</h4>
					<h6>{{ terrain.size ? terrain.size + ' kb' : '-' }}</h6>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"

import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";

export default Vue.extend({
	name: "TerrainListMenu",
	data() {
		return {
			property: {
				property: 'terrain',
				object: null,
			},
			search: '',
			eventsOnly: false,
		}
	},
	computed: {
		items: function () {
			let t = this.$store.state.metaverse.manifest.terrain//.filter(terrainObj => typeof terrainObj.scene === 'string')

			if (t === null)
				return [];

			if (this.search !== '')
				t = t.filter(t => t.name.indexOf(this.search) > -1);

			if (this.eventsOnly)
				return t.filter(t => t.interactions);

			return t;
		}
	},
	methods: {
		...mapActions({
			manifestAdd: 'metaverse/manifestAdd',
		}),
		toggleEvents(e) {
			e.preventDefault()
			this.eventsOnly = !this.eventsOnly
		},

		select(id) {
			this.$emit('select', {menu: 'terrain', item_id: id})
		},
		add() {
			const newTerrainItem = {
				isNew: true,
				id: uuidv4(),
				scene: "",
				name: "",
				position: {
					x: 0,
					y: 0,
					z: 0
				},
				scale: {
					x: 1,
					y: 1,
					z: 1
				},
				rotation: {
					x: 0,
					y: 0,
					z: 0
				},
			}


			this.manifestAdd({
				...this.property,
				object: newTerrainItem,
			})

			this.$emit("add", newTerrainItem);
			this.select(newTerrainItem.id)
		}
	}
})
</script>

<style scoped>
.terrain {
	transition: all 0.2s ease-out;
}

h4 {
	font-size: 0.8em;
}

h6 {
	font-size: 0.6em;
	color: grey;
}

.terrain:hover {
	background: rgba(0, 0, 0, 0.9);
	border-color: rgba(255, 255, 255, 0.5);
	cursor: pointer;
}

.search {
	background: unset;
}
</style>
