<template>
	<div class="light ambient-light menu">
<!--		<div class="form-group">-->
<!--			<label>Light Type</label>-->
<!--			<input class="form-control" readonly v-model="form.type">-->
<!--		</div>-->

		<div class="form-group">
			<label>Color</label>
			<input class="form-control" type="color" v-model="form.color" @input="$emit('update', form)">
		</div>

		<div class="form-group">
			<label>Intensity</label>
			<div class="row">
				<div class="col-4">
					<input class="form-control menu-control" type="number" min="0" max="1" step="0.1"  v-model.number="form.intensity" @input="$emit('update', form)">
				</div>
				<div class="col-8">
					<input class="btn-block mt-2" type="range" v-model.number="form.intensity" @input="$emit('update', form)" min="0.0" max="1.0" step="0.01">
				</div>
			</div>
		</div>

		<div class="form-group">
			<label>Width</label>
			<input class="form-control" type="number" min="0.0" v-model.number="form.width" @input="$emit('update', form)" step="0.1">
		</div>

		<div class="form-group">
			<label>Height</label>
			<input class="form-control" type="number" min="0.0" v-model.number="form.height" @input="$emit('update', form)" step="0.1">
		</div>

		<hr>
		<div class="form-group">
			<label>Position</label>
			<Vector3 v-model="form.position" @input="$emit('update', form)" />
		</div>
		<div class="form-group">
			<label>Rotation</label>
			<Vector3 v-model="form.rotation" @input="$emit('update', form)" />
		</div>
	</div>
</template>

<script>
import Vector3 from "../fields/Vector3";

export default {
	name: "PlaneLight",
	components: {Vector3},
	props: {
		light: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			form: {
				type: "plane",
				color: "#ffffff",
				intensity: 1,
				width: 5,
				height: 5,
				position: [0, 0, 0],
				rotation: [0, 0, 0],
			},
		}
	},
	watch: {
		light: {
			handler(newValue) {
				this.form = newValue
			}
		},
	},
	beforeMount() {
		this.form = this.light
	}
}
</script>

<style scoped>

</style>
