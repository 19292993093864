var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form", { staticClass: "menu" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Object")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.target_id,
            expression: "form.target_id",
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.form.target_id },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "target_id", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Axis")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.axis,
              expression: "form.axis",
            },
          ],
          staticClass: "form-control",
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.form,
                "axis",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        _vm._l(["x", "y", "z"], function (axis) {
          return _c("option", { key: axis, domProps: { value: axis } }, [
            _vm._v(_vm._s(axis)),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Duration")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.form.duration,
            expression: "form.duration",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.form.duration },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "duration", _vm._n($event.target.value))
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Speed")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.form.delta,
            expression: "form.delta",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.form.delta },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "delta", _vm._n($event.target.value))
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Block Movement")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.blocking,
            expression: "form.blocking",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.form.blocking)
            ? _vm._i(_vm.form.blocking, null) > -1
            : _vm.form.blocking,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.form.blocking,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 && _vm.$set(_vm.form, "blocking", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.form,
                    "blocking",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.form, "blocking", $$c)
            }
          },
        },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }