<template>
	<div class="vector-3">
		<div class="row">
			<div class="col-sm-12 col-md-4 row">
				<label class="col-form-label col-sm-auto blue">X:</label>
				<div class="col-sm-10 px-0">
					<input class="form-control" type="number" step="0.01" v-model.number="form.x"
								 @change="$emit('input',integerForm)">
				</div>
			</div>

			<div class="col-sm-12 col-md-4 row">
				<label class="col-form-label col-sm-auto green">Y:</label>
				<div class="col-sm-10 px-0">
					<input class="form-control" type="number" step="0.01" v-model.number="form.y"
								 @change="$emit('input',integerForm)">
				</div>
			</div>

			<div class="col-sm-12 col-md-4 row">
				<label class="col-form-label col-sm-auto red">Z:</label>
				<div class="col-sm-10 px-0">
					<input class="form-control" type="number" step="0.01" v-model.number="form.z"
								 @change="$emit('input',integerForm)">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: "Vector3",
	props: {
		value: {
			required: true
		},
	},
	data() {
		return {
			isArray: false,
			form: {
				x: 0.00,
				y: 0.00,
				z: 0.00
			}
		}
	},
	computed: {
		integerForm: function () {
			if (this.isArray) {
				return [
					parseFloat(this.form.x),
					parseFloat(this.form.y),
					parseFloat(this.form.z)
				]
			}


			return {
				x: parseFloat(this.form.x),
				y: parseFloat(this.form.y),
				z: parseFloat(this.form.z)
			}
		},

		x: function () {
			const i = Array.isArray(this.value) ? this.value[0] : this.value.x || 0;
			return i.toFixed(2);
		},
		y: function () {
			const i = Array.isArray(this.value) ? this.value[1] : this.value.y || 0;
			return i.toFixed(2);
		},
		z: function () {
			const i = Array.isArray(this.value) ? this.value[2] : this.value.z || 0;
			return i.toFixed(2);
		}
	},
	watch: {
		value: {
			handler() {
				if (!this.value) {
					this.form = {
						x: 0,
						y: 0,
						z: 0
					}
					return
				}

				this.isArray = Array.isArray(this.value);
				this.form = {
					x: this.x || 0,
					y: this.y || 0,
					z: this.z || 0
				}
			},
			immediate: true,
		}
	},
}
</script>

<style scoped>
.form-control {
	font-size: 0.8rem;
	background: unset;
	color: white;
	text-align: center;
	padding: 12px;
}

.blue {
	text-align: right;
	font-weight: bolder;
	color: darkcyan;
}

.green {
	text-align: right;
	font-weight: bolder;
	color: darkgreen;
}

.red {
	text-align: right;
	font-weight: bolder;
	color: darkred;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  cursor: pointer;
}

</style>
