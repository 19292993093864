<template>
	<div class="menu lighting-menu mt-4 mb-3">
		<div class="row">
			<div class="col">
				<h3 class="mb-2">Lighting</h3>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="deselect">Back</button>
			</div>
		</div>
		<!--		<code>{{ item }}</code>-->

		<div class="form-group">
			<label>ID</label>
			<input class="form-control" :value="form.id" disabled>
		</div>


		<div class="form-group">
			<label>Light Type</label>
			<select class="form-control" v-model="form.type" @change="lightTypeChange">
				<option value="ambient">Ambient</option>
				<option value="spot">Spot</option>
				<option value="point">Point</option>
				<option value="hemisphere">Hemisphere</option>
				<option value="rectarea">Plane</option>
			</select>
		</div>

		<hr>

		<AmbientLight @update="onLightUpdate" :light="form" v-if="form.type === 'ambient'"></AmbientLight>
		<SpotLight @update="onLightUpdate" :light="form" v-if="form.type === 'spot'"></SpotLight>
		<PointLight @update="onLightUpdate" :light="form" v-if="form.type === 'point'"></PointLight>
		<HemisphereLight @update="onLightUpdate" :light="form" v-if="form.type === 'hemisphere'"></HemisphereLight>
		<PlaneLight @update="onLightUpdate" :light="form" v-if="form.type === 'rectarea'"></PlaneLight>

		<hr>

		<div class="text-center">
			<button @click="deleteItem" class="btn btn-danger mt-3 btn-wide">Delete</button>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import { mapActions } from "vuex"

import StaticObject from "../classes/StaticObject";
import Vector3 from "../fields/Vector3";
import AmbientLight from "../lights/AmbientLight";
import SpotLight from "../lights/SpotLight";
import HemisphereLight from "../lights/HemisphereLight";
import PointLight from "../lights/PointLight";
import PlaneLight from "../lights/PlaneLight";

export default Vue.extend({
	name: "LightingMenu",
	components: {PlaneLight, PointLight, HemisphereLight, SpotLight, AmbientLight, Vector3, StaticObject},
	props: {
		meta: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			form: {},
			property: {
				property: 'lighting',
				object: null,
			},
		}
	},
	computed: {
		item() {
			return this.$store.state.metaverse.manifest.lighting.filter(d => {
				return d.id === this.meta
			})[0]
		},
	},
	watch: {
		item: {
			handler(newValue) {
				this.form = newValue
			}
		}
	},
	methods: {
		...mapActions({
			manifestUpdate: 'metaverse/manifestUpdate',
			manifestDelete: 'metaverse/manifestDelete',
		}),

		deselect() {
			this.$emit('select', {menu: 'lighting', item_id: null})
		},
		deleteItem() {
			this.manifestDelete({
				...this.property,
				object: this.form,
			})

			this.$emit('delete', this.form.id)
			this.deselect()
		},
		onLightUpdate(data){
			this.form = Object.assign(this.form, data)

			this.update()
		},

		update() {
			this.manifestUpdate({
				...this.property,
				object: this.form,
			})

			this.$emit("update", this.form);
		},
		lightTypeChange() {
			console.log("Light changed: ", this.form.type)

			const light = {
				id: this.form.id,
				type: this.form.type,
				position: this.form.position || [0,0,0],
				intensity: this.form.intensity || 1,
				shadow: {
					enabled: true,
					bias: -0.003,
					near: 0.5,
					far: 500,
				},
			};

			switch (this.form.type) {
				case "spot":
					light.color = this.form.color || '#ffffff';
					light.direction = [0, -1, 0]
					light.target = this.form.target || [0,0,0]
					light.distance = 0;
					light.decay = 1;
					light.angle = 0;
					light.penumbra = 1;
					break;
				case "point":
					light.color = this.form.color || '#ffffff';
					light.distance = 0;
					light.decay = 1;
					break;
				case "rectarea":
					light.color = this.form.color || '#ffffff';
					light.rotation = this.form.position || [0,0,0];
					light.intensity = this.form.intensity || 1;
					light.width = this.form.width || 5;
					light.height = this.form.height || 5;
					break;
				case "hemisphere":
					light.color_sky = this.form.color || '#ffffff';
					light.color_ground = this.form.color || '#ffffff';
					break;
				case "ambient":
					light.color = this.form.color || '#ffffff';
					break;
			}

			this.form = light
			this.update()
		}
	},
	mounted() {
		console.log("Setting light", this.item)
		this.form = Object.assign({}, this.item);
	}
})
</script>

<style scoped>
h4 {
	text-align: left;
}
</style>
