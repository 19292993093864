var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "light spot-light menu" }, [
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Color")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { type: "color" },
        domProps: { value: _vm.form.color },
        on: { change: (e) => (_vm.form.color = e.target.value) },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Distance")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.number",
            value: _vm.form.distance,
            expression: "form.distance",
            modifiers: { number: true },
          },
        ],
        staticClass: "form-control",
        attrs: { type: "number", min: "0.0", step: "0.1" },
        domProps: { value: _vm.form.distance },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.form, "distance", _vm._n($event.target.value))
          },
          blur: function ($event) {
            return _vm.$forceUpdate()
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Intensity")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.intensity,
                expression: "form.intensity",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control menu-control",
            attrs: { type: "number", min: "0", step: "0.1" },
            domProps: { value: _vm.form.intensity },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "intensity", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-8" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.intensity,
                expression: "form.intensity",
                modifiers: { number: true },
              },
            ],
            staticClass: "btn-block mt-2",
            attrs: { type: "range", min: "0.0", max: "2.0", step: "0.01" },
            domProps: { value: _vm.form.intensity },
            on: {
              __r: function ($event) {
                _vm.$set(_vm.form, "intensity", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Angle")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.form.angle,
                expression: "form.angle",
              },
            ],
            staticClass: "form-control menu-control",
            attrs: {
              type: "number",
              min: "0.0",
              max: Math.PI / 2,
              step: "0.1",
            },
            domProps: { value: _vm.form.angle },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "angle", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-8" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.angle,
                expression: "form.angle",
                modifiers: { number: true },
              },
            ],
            staticClass: "btn-block mt-2",
            attrs: { type: "range", min: "0.0", max: Math.PI / 2, step: "0.1" },
            domProps: { value: _vm.form.angle },
            on: {
              __r: function ($event) {
                _vm.$set(_vm.form, "angle", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Decay")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.decay,
                expression: "form.decay",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control menu-control",
            attrs: { type: "number", min: "0", step: "0.1" },
            domProps: { value: _vm.form.decay },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "decay", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-8" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.decay,
                expression: "form.decay",
                modifiers: { number: true },
              },
            ],
            staticClass: "btn-block mt-2",
            attrs: { type: "range", min: "0.0", max: "2.0", step: "0.01" },
            domProps: { value: _vm.form.decay },
            on: {
              __r: function ($event) {
                _vm.$set(_vm.form, "decay", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Penumbra")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-4" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.penumbra,
                expression: "form.penumbra",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control menu-control",
            attrs: { type: "number", min: "0", step: "0.1" },
            domProps: { value: _vm.form.penumbra },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "penumbra", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-8" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.penumbra,
                expression: "form.penumbra",
                modifiers: { number: true },
              },
            ],
            staticClass: "btn-block mt-2",
            attrs: { type: "range", min: "0.0", max: "1.0", step: "0.01" },
            domProps: { value: _vm.form.penumbra },
            on: {
              __r: function ($event) {
                _vm.$set(_vm.form, "penumbra", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Position")]),
        _vm._v(" "),
        _c("Vector3", {
          model: {
            value: _vm.form.position,
            callback: function ($$v) {
              _vm.$set(_vm.form, "position", $$v)
            },
            expression: "form.position",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group" },
      [
        _c("label", [_vm._v("Target")]),
        _vm._v(" "),
        _c("Vector3", {
          model: {
            value: _vm.form.target,
            callback: function ($$v) {
              _vm.$set(_vm.form, "target", $$v)
            },
            expression: "form.target",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Shadow Enabled")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.shadow.enabled,
            expression: "form.shadow.enabled",
          },
        ],
        attrs: { type: "checkbox" },
        domProps: {
          checked: Array.isArray(_vm.form.shadow.enabled)
            ? _vm._i(_vm.form.shadow.enabled, null) > -1
            : _vm.form.shadow.enabled,
        },
        on: {
          change: function ($event) {
            var $$a = _vm.form.shadow.enabled,
              $$el = $event.target,
              $$c = $$el.checked ? true : false
            if (Array.isArray($$a)) {
              var $$v = null,
                $$i = _vm._i($$a, $$v)
              if ($$el.checked) {
                $$i < 0 &&
                  _vm.$set(_vm.form.shadow, "enabled", $$a.concat([$$v]))
              } else {
                $$i > -1 &&
                  _vm.$set(
                    _vm.form.shadow,
                    "enabled",
                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                  )
              }
            } else {
              _vm.$set(_vm.form.shadow, "enabled", $$c)
            }
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.form.shadow.enabled,
            expression: "form.shadow.enabled",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("label", [_vm._v("Shadow Bias")]),
        _vm._v(" "),
        _c("div", { staticClass: "row mt-2" }, [
          _c("div", { staticClass: "col-4" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.form.shadow.bias,
                  expression: "form.shadow.bias",
                  modifiers: { number: true },
                },
              ],
              staticClass: "form-control menu-control",
              attrs: { type: "number", min: "-1", step: "0.0001" },
              domProps: { value: _vm.form.shadow.bias },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.form.shadow, "bias", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-8" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.number",
                  value: _vm.form.shadow.bias,
                  expression: "form.shadow.bias",
                  modifiers: { number: true },
                },
              ],
              staticClass: "btn-block mt-2",
              attrs: { type: "range", min: "-1", max: "0.1", step: "0.001" },
              domProps: { value: _vm.form.shadow.bias },
              on: {
                __r: function ($event) {
                  _vm.$set(_vm.form.shadow, "bias", _vm._n($event.target.value))
                },
                blur: function ($event) {
                  return _vm.$forceUpdate()
                },
              },
            }),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.form.shadow.enabled,
            expression: "form.shadow.enabled",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("label", [_vm._v("Start")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "number", min: "0", step: "0.1" },
          domProps: { value: _vm.form.shadow.near },
        }),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.form.shadow.enabled,
            expression: "form.shadow.enabled",
          },
        ],
        staticClass: "form-group",
      },
      [
        _c("label", [_vm._v("End")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "number", min: "0", step: "0.1" },
          domProps: { value: _vm.form.shadow.far },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }