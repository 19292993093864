<template>
	<div class="menu entity-menu">
		<div class="row mt-3">
			<div class="col">
				<h3 class="mb-2">Interactables</h3>
			</div>
			<div class="col">
				<button class="btn btn-outline-secondary btn-sm float-end" @click="onClone">Clone</button>
			</div>
			<div class="col">
				<button class="btn btn-outline-primary btn-sm float-end" @click="deselect">Back</button>
			</div>
		</div>
		<!--		<code>{{ item }}</code>-->

		<div class="form-group">
			<label>ID</label>
			<input :value="form.id" disabled class="form-control">
		</div>

		<div class="form-group">
			<label>Name</label>
			<input v-model="form.name" class="form-control">
		</div>


		<!--		<div class="form-group">-->
		<!--			<label>Class</label>-->
		<!--			<input readonly :value="form.class" class="form-control">-->
		<!--			&lt;!&ndash;			TODO Select&ndash;&gt;-->
		<!--			<i class="sub">More coming very soon!</i>-->
		<!--		</div>-->

		<!--		<hr class="my-3">-->

		<!--		TODO Show properties based on class selected-->
		<StaticObject
				v-if="form.class === 'StaticObject'"
				:alpha-id="form.id"
				:data="form.data"
				:interactions="form.interactions"
				@updateEntity="updateEntity"
				@updateInteractions="updateInteractions"
		/>

		<hr>

		<div class="text-center">
			<button @click="deleteItem" class="btn btn-danger mt-3 btn-wide">Delete</button>
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapActions} from "vuex"
import {uuidv4} from "@alphabatem/js_sdk/src/common/utils";

import StaticObject from "@/components/editor/classes/StaticObject";

export default Vue.extend({
	name: "EntityMenu",
	components: {
		StaticObject
	},
	props: {
		meta: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			form: {},
			property: {
				property: 'entity',
				object: null,
			},
		}
	},
	computed: {
		item() {
			return this.$store.state.metaverse.manifest.entities.filter(d => {
				return d.id === this.meta
			})[0]
		},
	},
	watch: {
		item: {
			handler(newValue) {
				this.form = newValue
			},
		},
	},
	methods: {
		...mapActions({
			manifestUpdate: 'metaverse/manifestUpdate',
			manifestAdd: 'metaverse/manifestAdd',
			manifestDelete: 'metaverse/manifestDelete',
		}),

		update() {
			this.manifestUpdate({
				...this.property,
				object: this.form,
			})

			this.$emit("update", this.form);
		},
		deselect() {
			this.$emit('select', {menu: 'entities', item_id: null})
		},
		onClone(e) {
			e.preventDefault()

			const obj = Object.assign({}, this.form)
			obj.id = uuidv4()
			obj.isNew = true
			obj.data = Object.assign({}, this.form.data)
			obj.interactions = Object.assign({}, this.form.interactions)
			obj.modified = Object.assign({}, this.form.modified)

			this.manifestAdd({
				...this.property,
				object: obj,
			})

			this.$emit("add", obj);
			this.$emit('select', {menu: 'entities', item_id: obj.id})
		},

		updateEntity(data) {
			this.form = {
				...this.form,
				...data,
			}

			this.update()
		},
		updateInteractions(interaction) {
			this.form.interactions = {
				...this.form.interactions,
				...interaction,
			}

			this.update()
		},
		deleteItem() {
			this.manifestDelete({
				...this.property,
				object: this.form,
			})

			this.$emit('delete', this.form.id)
			this.deselect()
		},
	},
	mounted() {
		this.form = this.item

		if (!this.form.interactions.click) {
			this.form.interactions = {
				...this.form.interactions,
				click: {
					action_type: null,
					data: {},
				},
			}
		}

		if (!this.form.interactions.proximity) {
			this.form.interactions = {
				...this.form.interactions,
				proximity: {
					action_type: null,
					activation_radius: 5,
					data: {},
				},
			}
		}

	}
})
</script>

<style scoped>
.sub {
	margin-left: 3%;
	color: grey;
}
</style>
