var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vector-3" }, [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12 col-md-4 row" }, [
        _c("label", { staticClass: "col-form-label col-sm-auto blue" }, [
          _vm._v("X:"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-10 px-0" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.x,
                expression: "form.x",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control",
            attrs: { type: "number", step: "0.01" },
            domProps: { value: _vm.form.x },
            on: {
              change: function ($event) {
                return _vm.$emit("input", _vm.integerForm)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "x", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 col-md-4 row" }, [
        _c("label", { staticClass: "col-form-label col-sm-auto green" }, [
          _vm._v("Y:"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-10 px-0" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.y,
                expression: "form.y",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control",
            attrs: { type: "number", step: "0.01" },
            domProps: { value: _vm.form.y },
            on: {
              change: function ($event) {
                return _vm.$emit("input", _vm.integerForm)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "y", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-12 col-md-4 row" }, [
        _c("label", { staticClass: "col-form-label col-sm-auto red" }, [
          _vm._v("Z:"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-10 px-0" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model.number",
                value: _vm.form.z,
                expression: "form.z",
                modifiers: { number: true },
              },
            ],
            staticClass: "form-control",
            attrs: { type: "number", step: "0.01" },
            domProps: { value: _vm.form.z },
            on: {
              change: function ($event) {
                return _vm.$emit("input", _vm.integerForm)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.form, "z", _vm._n($event.target.value))
              },
              blur: function ($event) {
                return _vm.$forceUpdate()
              },
            },
          }),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }