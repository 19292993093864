var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "menu list-menu" },
    [
      _c("div", { staticClass: "row mt-3" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "col" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-outline-primary btn-sm float-end",
              on: { click: _vm.add },
            },
            [_vm._v("Add Model")]
          ),
        ]),
      ]),
      _vm._v(" "),
      Object.keys(_vm.items).length === 0
        ? _c(
            "div",
            { staticClass: "card card-body text-center mt-4 no-items" },
            [
              _c("h2", { staticClass: "my-4" }, [_vm._v("No items")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-primary btn-sm btn-sm-rel",
                  on: { click: _vm.add },
                },
                [_vm._v("Add Model")]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.items, function (value, key) {
        return _c(
          "div",
          {
            key: key,
            staticClass: "card card-body p-3 px-2 my-2",
            on: {
              click: function ($event) {
                return _vm.select(key)
              },
            },
          },
          [
            _c("h2", { staticClass: "mb-0 text-start" }, [
              _vm._v(_vm._s(_vm.tidyName(key))),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Models")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }