<template>
	<div class="menu environment-menu mt-4 mb-3">
		<h3 class="text-center">Environment</h3>

		<div class="form-group">
			<label>Spawn Point</label>
			<Vector3 v-model="form.spawn_point" @input="update" />
		</div>

		<div class="form-group">
			<label>Player Speed</label>
			<input v-model.number="form.player_speed" @input="update" type="number" min="1" step="1" class="form-control"/>
		</div>

		<hr>
		<h6>Shadows</h6>

		<div class="form-group">
			<label>Shadows Enabled </label>
			<input type="checkbox" v-model="form.shadows" @input="update" />
		</div>

		<div class="form-group">
			<label>Shadow Quality </label>
			<select class="form-control" v-model="form.shadow_quality" @change="update">
				<option value="low">Low</option>
				<option value="medium">Medium</option>
				<option value="high">High</option>
				<option value="very-high">Very High</option>
			</select>
		</div>

		<hr>
		<h6>Sky</h6>

		<!--		<div class="form-group">-->
		<!--			<label>Skybox Image</label>-->
		<!--			&lt;!&ndash;			TODO&ndash;&gt;-->
		<!--			<input class="form-control" type="file">-->
		<!--		</div>-->

		<!--		<div class="form-group">-->
		<!--			<label>PBR Enabled</label>-->
		<!--			<input type="checkbox" :checked="true">-->
		<!--			&lt;!&ndash;			TODO&ndash;&gt;-->
		<!--		</div>-->


		<div class="form-group">
			<label>Background</label>
			<input type="color" v-model="form.background" @input="update" class="form-control">
		</div>


		<hr>
		<h6>Fog</h6>
		<div class="form-group">
			<label>Color</label>
			<input type="color" v-model="form.fog.color" @input="update" class="form-control">
		</div>

		<div class="form-group">
			<label>Start</label>
			<input type="number" v-model.number="form.fog.start" @input="update" class="form-control" min="0" step="1">
		</div>
		<div class="form-group">
			<label>End</label>
			<input type="number" v-model.number="form.fog.end" @input="update" class="form-control" min="0" step="1">
		</div>
	</div>
</template>

<script>
import Vue from 'vue'
import {mapActions} from "vuex"

import Vector3 from "../fields/Vector3";

export default Vue.extend({
	name: "EnvironmentMenu",
	components: {Vector3},
	data() {
		return {
			property: {
				property: 'environment',
				object: null,
			},
			displayProperty: {
				property: 'display',
				object: null,
			},
			form: {
				spawn_point: [],
				shadows: true,
				shadow_quality: 'medium',
				player_speed: 10, //Default
				background: '#ffffff',
				fog: {},
			}
		}
	},
	computed: {
		item: function () {
			return this.$store.state.metaverse.manifest.environment;
		}
	},
	watch: {
		item: {
			handler(newValue) {
				this.form = newValue
			},
			deep: true,
		}
	},
	methods: {
		...mapActions({
			manifestUpdate: 'metaverse/manifestUpdate',
		}),

		update() {
			this.manifestUpdate({
				...this.property,
				object: this.form,
			})

			this.$emit("update", this.form);
		}
	},
	beforeMount() {
		console.log("EnvironmentMenu::beforeMount", this.item)
		this.form = {
			...this.form,
			...this.item,
		}
	}
})
</script>

<style scoped>
</style>
