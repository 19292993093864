var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "menu character-menu" }, [
    _c("div", { staticClass: "row mt-3" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "col" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-outline-primary btn-sm float-end",
            on: { click: _vm.deselect },
          },
          [_vm._v("Back")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _vm.$store.state.metaverse.manifest.models[_vm.form.model]
      ? _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("model-viewer", {
              staticClass: "viewer",
              staticStyle: { width: "100%" },
              attrs: {
                "auto-rotate": "",
                src: _vm.$store.state.metaverse.manifest.models[_vm.form.model],
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("ID")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        attrs: { disabled: "" },
        domProps: { value: _vm.form.id },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Name")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.name,
            expression: "form.name",
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.form.name },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "name", $event.target.value)
            },
            _vm.update,
          ],
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Summary")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form.summary,
            expression: "form.summary",
          },
        ],
        staticClass: "form-control",
        domProps: { value: _vm.form.summary },
        on: {
          input: [
            function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.form, "summary", $event.target.value)
            },
            _vm.update,
          ],
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Model")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.model,
              expression: "form.model",
            },
          ],
          staticClass: "form-control",
          on: {
            update: _vm.update,
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.form,
                "model",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        _vm._l(
          _vm.$store.state.metaverse.manifest.models,
          function (model, key) {
            return _c("option", { key: key, domProps: { value: key } }, [
              _vm._v(_vm._s(key)),
            ])
          }
        ),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Device Type")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.form.device_type,
              expression: "form.device_type",
            },
          ],
          staticClass: "form-control",
          on: {
            update: _vm.update,
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.$set(
                _vm.form,
                "device_type",
                $event.target.multiple ? $$selectedVal : $$selectedVal[0]
              )
            },
          },
        },
        [
          _c("option", { attrs: { value: "3D" } }, [_vm._v("1st Person")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "3D3RD" } }, [_vm._v("3rd Person")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "OBSERVER" } }, [
            _vm._v("Free Movement"),
          ]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "form-group mb-3" },
      [
        _c("label", [_vm._v("Scale")]),
        _vm._v(" "),
        _c("Vector3", {
          on: { input: _vm.update },
          model: {
            value: _vm.form.scale,
            callback: function ($$v) {
              _vm.$set(_vm.form, "scale", $$v)
            },
            expression: "form.scale",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "text-center" }, [
      _c("hr"),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-danger mt-3 btn-wide",
          on: { click: _vm.deleteItem },
        },
        [_vm._v("Delete")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "col" }, [
      _c("h3", { staticClass: "mb-2" }, [_vm._v("Character")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }