<template>
	<form class="menu">
		<div class="form-group">
			<label>Object</label>
			<input v-model="form.target_id" class="form-control"/>
		</div>
		<div class="form-group">
			<label>Axis</label>
			<select class="form-control" v-model="form.axis">
				<option v-for="axis in ['x', 'y', 'z']" :key="axis" :value="axis">{{ axis }}</option>
			</select>
		</div>
		<div class="form-group">
			<label>Duration</label>
			<input v-model.number="form.duration" class="form-control"/>
		</div>
		<div class="form-group">
			<label>Speed</label>
			<input v-model.number="form.delta" class="form-control"/>
		</div>
		<div class="form-group">
			<label>Block Movement</label>
			<input type="checkbox" v-model="form.blocking"/>
		</div>
	</form>
</template>

<script>
import Vue from 'vue'
import actionsMixin from '@/components/editor/actions/actionsMixin'

export default Vue.extend({
	name: 'RotateObject',
	mixins: [actionsMixin],
	data() {
		return {
			form: {
				target_id: null,
				axis: 'x',
				duration: 1000,
				delta: 0.01,
				blocking: true
			},
		}
	},
})
</script>

<style lang="scss" scoped>
.form-control {
	font-size: 0.8rem;
	background: unset;
	color: white;
	text-align: left;
}
</style>
